.products {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 26px;
  height: 100%;

  .general-popup.select-category {
    width: 331px;
    left: -1px;
    box-shadow: 0 2px 6px #0000000d;
    font-family: Open Sans, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
  }
}

.search-open-bar-btns.products-search-btns .filter-btn:first-of-type {
  width: 226px;
}

.products-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;

  .search-and-filters-bar {
    margin-right: 0;
    grid-area: bar;
  }
}

.products-header_right-inner {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.products-create-btn {
  @extend .order-create-btn;

  grid-area: create;
}

.products__inner {
  display: flex;
  justify-content: space-between;
  column-gap: 24px;
}

.products-op-title-inner {
  display: flex;
  flex-direction: column;
}

.products-op-title-brand {
  font-size: 10px;
}

.merchery-label.inline-input {
  font-size: 14px;
  line-height: 19px;
  color: #292D38;
  background-color: transparent;
  display: inline-flex;
}

.inline-input .merchery-label__input {
  text-align: right;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 17px;
  border: 1px solid transparent;
  transition: ease box-shadow .2s;

  &:focus {
    background: #FFFFFF;
    border: 1px solid rgba(80, 121, 224, 0.3);
    box-shadow: 0 0 6px rgba(80, 121, 224, 0.5);
    border-radius: 4px;
  }
}

.product-page-variants-table-items-wrapper {
  transform: rotateX(180deg);
}
.table-items.product-page-variants-table-items {
  transform: rotateX(180deg);
  position: relative;
}

.products-table__wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.products .table-items, .table-items.product-page-variants-table-items {

  .icon-wrapper {
    padding: 10px;
    margin: -10px;
    cursor: pointer;
    color: rgb(36, 85, 214);
    display: flex;

    &:hover, &:focus {
      text-shadow: 0 0 10px rgba(36, 85, 214, 0.3);
    }

    i {
      display: flex;
      align-items: center;
      width: max-content;
      margin-left: 4px;
      pointer-events: none;
      font-size: 19px;
      transition: transform ease 0.07s;
    }

    &.hided-subcategories i {
      transform: rotate(-90deg);
    }
  }

  .table-item {
    overflow: hidden;
    display: grid;
    grid-template-columns: 68px 56px auto 11% 8% 12% 50px;
    column-gap: 1.5%;
    grid-auto-flow: column;
    cursor: pointer;
    background-color: hsl(0deg 0% 100%);
    min-height: 85px;

    &.new-entity {
      background: #EDFAF1;
    }

    &.selected {
      background-color: #F6F7FF;
    }

    &.moy-sklad-item {
      grid-template-columns: 83px auto 11% 8% 12%;
    }

    &.deleted-variant {
      background: rgba(250, 251, 251, 1);
      & > div:not(.op-config):not(.op-check) {
        opacity: .5;
      }
    }

    &.variant-table-item {
      grid-template-columns: 79px 56px auto 11% 8% 12% 50px;
      .op-check {
        padding-left: 50px;
      }
    }

    & > div.op-config {
      padding-left: 7px;
      justify-content: space-around;
      padding-right: 3px;

      .show-btn {
        width: 100%;
      }

      .edit-btn, .delete-btn, .undo-btn {
        font-size: 16px;
        padding: 5px;
        margin: -5px;
      }
    }

    &.with-variants {
      grid-template-columns: 68px 56px auto 34% 50px;
    }

    & > div.op-check {
      padding-right: 6px;
      justify-content: flex-end;
  
      .grab-zone {
        width: 6px;
        display: flex;
        padding: 15px;
        margin: -15px;
        margin-right:  0;
        box-sizing: content-box;
  
        img {
          display: none;
          user-select: none;
        }
      }
    }

    & > div {
      display: flex;
      align-items: center;
    }

    & > .op-title {
      overflow: auto;
    }

    &.hidden {
      & > div:not(.op-config):not(.op-check):not(.op-show-more) {
        opacity: .5; 
      }
  
      & > div.op-config i {
        opacity: .5;
  
        &:focus {
          opacity: 1;
        }
      }
  
      &:hover, &:focus {
        .op-config i {
          opacity: 1;
        }
      }
    }

    &:hover {
      .inline-input input {
        border: 1px solid $lightGrayColor;
        background: #FFFFFF;
      }
    
      & > div.op-check .grab-zone img {
        display: flex;
      }
  
      & > div.op-config i {
        opacity: 1;
      }
    }
  }

  .table-items-header {
    position: sticky;
    top: -1px;
    z-index: 2;
    will-change: transform;

    &.selected-variants-menu {
      .table-item > div.op-config i {
        font-size: 20px;
      }
    }

    &.after-top-panel {
      top: 60px;
    }
  }

  .table-items-header.header-menu.table-item {
    grid-template-columns: 68px auto;
  }
  
  .table-items-header.table-item {
    height: 57px;
    min-height: 57px;
    font-size: 10px;
    user-select: none;
    cursor: default;

    &:focus {
      & > div.op-config i {
        opacity: 1;
      }
    }

    & > div.op-config {
      padding-left: 7px;
      justify-content: center;
      padding-right: 3px;
  
      i {
        font-size: 14px;
  
        &:focus {
          opacity: 1;
        }
      }
    }

  }
}

.product-variants-rows-wrapper {
  
}

.product-page-variants-table-items {
  .table-item:not(.table-items-header) {
    div.op-image > div {
      border: 1px transparent solid;

      &:hover, &:focus {
        border: 1px dashed rgba(74, 82, 103, 0.5);
        border-radius: 4px;
      }
    }
  }

  .op-weight {
    justify-content: end;
  }
}

.grabble {
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;

  &:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }
}

.moving {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.general-popup.product-filters-popup {
  left: unset;
  right: 0;
  top: 100%;

  & .product-filter-container {
    padding: 12px;
    width: 100%;
    text-align: left;
  }
}

.general-popup.product-filters {
  left: unset;
  right: 100%;
  top: 0;
}

.replace-copy-popup .my-select {
  margin-top: -6px;
}

.to-products-btn {
  border: 1px solid $lightGrayColor;
  border-radius: 4px;
  font-size: 12px;
  line-height: 16px;
  opacity: 0.7;
  display: flex;
  align-items: center;
  padding: 5px;
  width: max-content;
  background-color: #FFFFFF;

  &:hover, &:focus {
    border-color: $activeSecondColor;
    text-shadow: 0 0 10px rgba(36, 85, 214, 0.3);
    color: $activeSecondColor;
  }

}

.product-page {
  @extend .create-order;
  display: grid;
  grid-template-columns: 1fr 288px;
  grid-template-areas:
    "title title"
    "media sidebar"
    "options sidebar"
    "props sidebar"
    "desc sidebar";
  column-gap: 16px;
  grid-auto-rows: min-content;
  width: 100%;
}

.product-page-header {
  display: flex;
  align-items: center;
  row-gap: 24px;
  position: relative;
  grid-area: title;
  justify-content: space-between;
}

.product-page-header_left-inner {
  column-gap: 23px;
  display: flex;
  align-items: center;
  h2 {
    font-size: 24px;
    line-height: 33px;
  }

  .sub-text {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4A5267;
    font-size: 27px;
    position: relative;
  }
}

.product-page-element {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #FFFFFF;
  width: 100%;
}

.product-page-element.product-page-variants {  
  row-gap: 8px;
}

.top-bottom_paddings {
  padding-top: 24px;
  padding-bottom: 24px;
}

.product-page-main-labels {
  display: grid;
  grid-template-areas:  "name name name name"
                        "price discount purchase-price .";
  grid-template-columns: 22% 22% 22% 22%;
  grid-template-rows: auto;
  column-gap: 4%;
}

.purchase-price {grid-area: purchase-price;}
.name {grid-area:  name ;}
.vendor-code {grid-area:  vendor-code ;}
.barcode {grid-area:  barcode ;}
.price {grid-area:  price ;}
.discount {
  row-gap: unset;
  grid-area:  discount ;
}
.ship_in_packages {
  grid-column: 1 /-1;
}
.price_per_pack {
  grid-column: 1 /-1;
}

.inputs-row {
  display: flex;
  flex-direction: column;
  row-gap: 9px;
  width: 100%;

  &.disable {
    opacity: .7;
    pointer-events: none;
  }

  &.discount {
    row-gap: 0;
    min-width: 150px;
  }
}

.product-page-h3 {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  grid-column: 1 / -1;
}

.switch-headers-wrapper {
  display: flex;
  padding: 0 4px;
  column-gap: 8px;
}

.switch-headers {
  padding-bottom: 8px;
  margin-bottom: -0.5px;
  z-index: 2;

  &.active {
    opacity: 1;
    border-bottom: solid 1px $blackSecondColor;
  }

  &:hover, &:focus {
    color: $activeSecondColor;
    border-bottom-color: $activeSecondColor;
  }
}

.side-padding-24 {
  padding-left: 24px;
  padding-right: 24px;
}

.side-padding-16 {
  padding-left: 16px;
  padding-right: 16px;
}

.side-padding-36 {
  padding-left: 36px;
  padding-right: 36px;
}

.side-padding-42 {
  padding-left: 42px;
  padding-right: 42px;
}

.product-page__options-and-variants__wrapper {
  grid-area: options;
  flex-direction: column;
  display: flex;
  row-gap: 20px;
}
.product-page-additional-options {
  padding: 24px 0;
}
.product-page-additional-options-header {
  //padding: 24px;
}
.product-page-additional-options-header {
  display: flex;
}
.additional-options-switcher {
  padding: 0 24px;
}
.product-page-additional-options-add-option {
  color: $activeSecondColor;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  column-gap: 20px;
  align-items: center;
  padding: 0 24px;
  height: 65px;
  margin: -24px 0;
  transition: ease .2s box-shadow;

  i {
    font-size: 17px;
  }

  &:focus {
    box-shadow: 0 2px 6px rgb(0 0 0 / 15%);
  }
}
.product-page-additional-options-one-option {
  display: flex;
  flex-direction: column;
  row-gap: 28px;
  padding: 24px 0;
  border: solid 1px #E6E6E6;
  margin-top: -1px;
  margin-left: -1px;
  margin-right: -1px;
  background: #fff;

  &.product-page-additional-options-one-option--warning {
    box-shadow: 0 0 6px rgba($redColor, 0.5);
    z-index: 10;
  }
}
.product-page-additional-options-options-wrapper {
  display: flex;
  flex-direction: column;
}
.product-page-additional-options-one-option-header {
  display: flex;
  column-gap: 24px;
  justify-content: space-between;
  padding: 0 24px;
  
  &.product-page-additional-options-one-option-header__edit {
    margin-top: 18px;
    flex-grow: 2;
  }
}
.product-page-additional-options-one-option-header-left, .product-page-additional-options-one-option-values-input-left {
  display: flex;
  column-gap: 25px;
  align-items: center;
  width: 100%;
  position: relative;

  .grab-zone {
    margin: -18px -14px;
    padding: 13px;

    &.disabled {
      cursor: not-allowed;

      img {
        pointer-events: none;
      }
    }
  }
}
.product-page-additional-options-one-option-values-input-left .grab-zone {
  margin: -18px -20px -18px -8px;
}
.product-page-additional-options-one-option-header-title-values {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  width: 100%;
}
.product-page-additional-options-one-option-header-title {
  color: $blackSecondColor;
  font-size: 13px;
  font-weight: 600;
  width: 100%;
}
.product-page-additional-options-one-option-header-values-wrapper {
  display: flex;
  column-gap: 4px;
  row-gap: 6px;
}
.product-page-additional-options-one-option-header-value {
  @include flex-center;
  background: #F1F1F1;
  border-radius: 11px;
  height: 22px;
  padding: 0 7px;
  width: fit-content;
  font-size: 12px;
  font-weight: 400;
  color: #000;
}
.product-page-additional-options-one-option-context-btn {
  @include text-btn;
  padding: 18px;
  margin: -18px;

  &.delete-btn:hover, &.delete-btn:focus {
    color: $redColor;
  }
}
.product-page-additional-options-one-option-header-title-values-wrapper {
  display: flex;
  flex-direction: column;
}
.product-page-additional-options-one-option-title {
  position: relative;
  width: 100%;
}
.product-page-additional-options-one-option-title-h {
  position: absolute;
  top: -24px;
}
.product-page-additional-options-one-option-values-h-wrapper {
  @extend .product-page-additional-options-one-option-title-h;

  display: flex;
  justify-content: space-between;

  left: 64px;
  right: 66px;
}
.product-page-additional-options-one-option-values-wrapper {
  padding: 0 24px;
  flex-wrap: wrap;
  position: relative;
  margin-top: 18px;
}
.product-page-additional-options-one-option-values-inputs {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
.product-page-additional-options-one-option-values-inputs-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
.product-page-additional-options-one-option-values-input-wrapper {
  display: flex;
  grid-column-gap: 25px;
  column-gap: 25px;
  align-items: center;
  width: 100%;
}
.product-page-additional-options-one-option-values-input-left .merchery-label, .product-page-additional-options-one-option-values-blank-value .merchery-label {
  width: 100%;
}

.product-page-additional-options-one-option-values-blank-value {
  padding: 0 40px 0 31px;
}
.one-option__save-btn__wrapper {
  display: flex;
  padding: 0 64px 0 55px;

  .white-btn {
    font-weight: 600;
    border-color: $grayColor;
  }
}
.product-page-additional-options-labels {
  display: grid;
  grid-template-areas: "vendor-code barcode .";
  grid-template-columns: 30% 30% 30%;
  grid-template-rows: auto;
  column-gap: 5%;
  row-gap: 24px;
}

.product-page-element.product-page-properties-and-characteristics {
  row-gap: 0;
  grid-area: props;
}

.product-page-properties {
  display: grid;
  grid-template-columns: 22% 22% 22% 22%;
  grid-template-rows: auto;
  flex-direction: column;
  row-gap: 16px;
  column-gap: 4%;
  border-bottom: 1px solid #E6E6E6;
  padding-top: 23px;
  padding-bottom: 25px;
}

.product-page-description {
  row-gap: unset;
  padding: unset;
  grid-area: desc;

  .inputs-row {
    padding: 16px 24px 20px;
  }
}

.product-page-description-header {
  height: 81px;
  padding: 22px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #E4E4E4;
}
.edit-btn {
  color: #AFAFAF;

  &:hover, &:focus {
    .icofont-ui-edit {
      color: $activeSecondColor;
    }
    
    .icofont-not-allowed {
      color: #000;
    }
  }
}
.delete-btn {
  color: #AFAFAF;

  &:hover, &:focus {
    color: $redColor;
  }
}
.undo-btn {
  color: #AFAFAF;

  &:hover, &:focus {
    color: $greenColor;
  }
}

.product-page-description-adt {
  border-bottom: 1px solid #E4E4E4;
}

.product-page-description-adt, .product-page-description-detailed-desc {
  padding: 16px 24px 20px;
  display: flex;
  flex-direction:  column;
  row-gap: 10px;

  h4 {
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.045em;
    text-transform: uppercase;
  }

  .sub-text {
    font-size: 12px;
    line-height: 16px;
  }
}

.product-page-variants {
  display: flex;
  flex-direction: column;
  padding: 0;
}
.product-page-variants-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  column-gap: 26px;
  padding: 24px 28px 0 24px;

  .left {
    display: flex;
    column-gap: 14px;
    flex: 1 0 auto;
  
    .sub-text {
      font-size: 12px;
      line-height: 16px;
      opacity: 0.7;
    }
  }
}
.clear-product-variants-filters {
  font-size: 10px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: $activeSecondColor;

  &:hover, &:focus {
    font-weight: 600;
  }
}
.product-page-variants-header-filters {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  column-gap: 24px;
  row-gap: 16px;
}
.product-page-variants-header-filter-wrapper {
  position: relative;
}
.product-page-variants-header-filter {
  display: flex;
  column-gap: 4px;
  align-items: center;
  color: $activeSecondColor;
  font-size: 13px;
  padding: 10px;
  margin: -10px;
  i {
    font-size: 16px;
  }
}
.table-filters-list.product-variants-filters {
  .filters {
    background: none;
    padding: 0 28px 0 24px;

    div {
      margin-bottom: 0;
      margin-top: 10px;
    }
  }
}
.table-items.product-page-variants-table-items {
  border: none;
  box-shadow: none;
  border-radius: 0;

  .table-item {
    grid-template-columns: 48px 56px auto 11% 8% 9% 12% 12% 40px;
    min-height: 86px;
    cursor: default;
    overflow-x: auto;

    div.op-config {
      padding-left: 5px;
      padding-right: 10px;
      position: sticky;
      right: 0;
      margin-left: -5px;
      background: inherit;
    }

    div.op-check {
      position: sticky;
      left: 0;
      z-index: 1;
      background: inherit;  
      padding-right: 12px;
      justify-content: flex-end;
    }

    &.deleted-variant {
      div.op-config {
        justify-content: center;
      }
    }

    &.selected {
      .op-check .checkbox:not(:disabled):focus {
        border: 1px solid #5079E0;
      }
    }
  }

  .op-weight .inline-input input{
    text-align: end;
    padding-right: 7px;
  }

  .op-vendor-code .inline-input input {
    padding: 0 12px;
  }

  .input-padding-off.inline-input input{
    padding: 0 4px;
  }

  .table-items-header {
    // &.variants-header-menu {
    //   grid-template-columns: 48px 56px auto 12% 10% 9% 13% calc(13% + 30px + 1.3%)
    // }

    &.selected-variants-menu {
      &.table-item {
        // grid-template-columns: 48px auto 12% 10% 9% 13% calc(13% + 30px + 1.3%);
  
        .op-variant {
          font-weight: 600;
          font-size: 14px;
          letter-spacing: 0;
          text-transform: none;
        }
      }
    }

    &.table-item {
      &.selected {
        border-color: $activeSecondColor;
      }
  
      div.op-config {
        padding-left: 0;
        padding-right: 15px;
        justify-content: flex-end;
        column-gap: 10px;
      }
    }
  }

  .inline-input {
    height: 42px;

    input {
      border: 1px solid hsl(0deg 0% 88%);
      background: hsl(0deg 0% 100%);
      text-align: start;
      height: 42px;
    }
  }
}

.table-item:not(.table-items-header) {
  .op-variant {
    font-weight: 600;
    font-size: 13px;
  }
}

.product-page__main-labels-and-media__wrapper {
  grid-area: media;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.product-page-dropzone {
  padding-top: 24px;
  padding-bottom: 28px;
  row-gap: 21px;
  
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.product-page-dropzone-header {
  display: flex;
  justify-content: space-between;

  .general-popup {
    top: calc(100% + 10px);
  }

  .blue-text {
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: $activeColor;
    position: relative;
    margin-right: 20px;

    &::after {
      position: absolute;
      content: "";
      background: url('../../../img/blue-down.png') no-repeat 50% 50%;
      top: 50%;
      right: -17px;
      transform: translate(0, -50%);
      width: 12px;
      height: 12px;
    }
  }
}

.product-page-option-possible-values {
  display: flex;
  column-gap: 8px;
}

.product-page-option-possible-value {
  padding: 0 8px;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  font-weight: 400;
  border-radius: 4px;

  &.popup-element:focus {
    @extend .product-page-option-possible-value--blue;
  }
}

.product-option-possible-values {
  right: 0;
  top: calc(100% + 5px);
  left: unset;
  min-width: max-content;
  
  .popup-group {
    overflow-y: auto;
    max-height: 270px;
  }

  .popup-element {
    padding: 3px 8px;

    &:hover, &:focus {
      @include popup-btn-hover;
    }

    &:first-of-type {
      padding-top: 7px;
    }
    &:last-of-type {
      padding-bottom: 7px;
    }
  }
}

.product-page-option-possible-value--blue {
  background: rgba(#4C5DFA, 7%);
  color: $activeSecondColor;
}

.product-options-possible-names-wrapper {
  width: 100%;
  border: 1px solid rgba($lightGrayColor, .5);
  display: flex;
  flex-direction: column;
  text-align: left;
}

.possible-name-loader {
  height: 100%;
  display: flex;
}

.product-options-possible-name {
  text-align: left;
  padding: 2px 12px;
  display: flex;
  font-size: 13px;
  height: 40px;
  align-items: center;
}

.row-item {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  border: 0 solid #E4E4E4;
  border-bottom-width: 1px;

  &.active {
    background: #F6F7FF;
  }

  &.disabled {
    opacity: .7;
    cursor: default;
  }

  &.row-item__disabled--off {
    opacity: 1;
  }
}

.row-item__attributes:not(.row-item__attributes--select) {
  color: #292D38;
  opacity: 0.7;
}

.row-item__attributes--select .my-select-input{
  flex: unset;
}

.row-item__attributes {
  display: flex;
  flex-wrap: wrap;
  row-gap: 4px;
  column-gap: 28px;
}

.row-item__attributes__attr {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 8px;
}

.row-item__attributes__attr__value {
  height: 20px;
  width: max-content;

  .merchery-label__input {
    height: unset;
    width: max-content;
    line-height: 16px;
    padding: 0 25px 0 6px;
  }
  .my-select-input__down-icon {
    right: 4px;
  }
}

.row-item__price {
  display: flex;
}

.show-variants__btn {
  cursor: pointer;
  color: rgb(36, 85, 214);
  display: flex;
}

.row-item:not(.disabled) {
  .show-variants__btn {
    &:focus, &:hover {
      text-shadow: 0 0 10px rgba(36, 85, 214, 0.3);
    }
  }
}

.show-variants__btn--shown i {
  transform: rotate(-90deg);
}

.uuid-input__header-text .__react_component_tooltip .multi-line {
  text-align: left;
  font-weight: 400;
}

.select-product-category {
  position: relative;
}

.select-product-category-btn {
  position: relative;
}

.input-like-button {
  background: #FFFFFF;
  border-radius: 4px;
  font-size: 14px;
  transition: ease all 0.3s;
  line-height: 19px;
  height: 42px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 22px 0 12px;
  flex-grow: 1;
  box-sizing: border-box;
  border: 1px solid #E1E1E1;

  &.height-48 {
    height: 48px;
  }

  &:hover {
    border: 1px solid #b5b9c4;
  }
}

.item__config {
  display: flex;
  justify-content: center;
  align-items: center;
}

.products_replace-and-copy_popup {
  padding: 0;
}

.products_replace-and-copy_popup_inner {
  padding: 25px 28px 0 27px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding-bottom: 0;
}

.products_replace-and-copy_popup_body {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.multi-products_change-complete_popup {
  display: flex;
  flex-direction: column;
  padding: 0;
  min-height: 413px;
  justify-content: space-between;
  row-gap: 0;
}

.multi-products_change-complete_popup_inner {
  padding: 25px 28px 0 27px;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 14px;
}

.confirm-popup_footer--with-btns {
  padding: 27px;
  background: #FBFBFC;
}

@media(max-width: 1180px) {
  .side-padding-24 {
    padding-left: 12px;
    padding-right: 12px;
  }

  .product-page-description .inputs-row {
    padding: 10px 12px 12px;
  }

  .product-page-description-header {
    padding: 16px 12px 18px;
    height: 52px;
  }

  .top-bottom_paddings {
    padding-top: 16px;
    padding-bottom: 18px;
  }

  //.product-page-additional-options {
  //  padding: 16px 0;
  //}

  .product-page-dropzone {
    padding-top: 16px;
  }

  .product-page {
    grid-template-columns: 1fr 228px;
  }
}