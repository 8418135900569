
.merchery-navbar {
  width: 230px;
  border-right: 1px solid rgba(#000, .1);
  padding: 35px 16px 50px 14px;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 19px;
  font-weight: 500;
  color: $blackSecondColor;

  li {
    position: relative;
    display: flex;
    flex-direction: column;

    a {
      height: 100%;
      padding: 7px 0 8px 44px;
      display: flex;
      font-weight: 500;

      &:hover, &:focus {
        @include popup-btn-hover;
      }

      &.sub-page {
        padding-left: 15px;
        margin-left: 29px;
      }

      &.active {
        color: $activeColor;
        background-color: rgba($activeColor, .05);
        border-radius: 4px;
      
        i {
          color: $activeColor;
          opacity: 1;
        }
        
        & .items-counter {
          color: $activeSecondColor;
          background-color: #dcdff6;
          pointer-events: none;
        }
      }
    }
    
    i {
      position: absolute;
      top: 7px;
      left: 9px;
      line-height: 20px;
      opacity: .8;
      font-size: 16px;
    }
  }

}

.items-counter {
  min-width: 24px;
  margin-bottom: -1px;
  text-align: center;
  padding: 0 5px;
  top: 8px;
  right: 9px;
  border-radius: 30px;
  font-weight: 600;
  font-size: 11px;
  text-align: center;
  box-sizing: border-box;
  color: $lightGrayColor;
  background: $grayColor;
  pointer-events: none;
  margin-left: 8px;

  &.active {
    
  }
}

.general-popup.sidebar-popup {
  width: unset;
  top: 60px;
}

@media(max-width: 1180px) {
  .merchery-navbar {
    position: absolute;
    display: none;
    background: $bgColor;
    border: none;
    box-shadow: 2px 0px 6px rgba(0, 0, 0, 0.15);
    z-index: 1;
    height: max-content;
    top: 60px;
  }

  .sidebar-popup .merchery-navbar {
    display: flex;
    position: unset;
  }
}