.visibility-select-section__wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 12px;

  .react-datepicker-popper {
    z-index: 2;
  }

  &.visibility-select-section__wrapper--fdirection-row {
    flex-direction: row;
  }
}

.visibility-select-section__post-setup-btn {
  justify-content: flex-start;
  color: #2455D6;
}

.visibility-select-section__wrapper .react-datepicker__input-container {
  display: none;
}

.visibility-date-reselect {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.visibility-date-reselect__text-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 4px;
}

.visibility-date-reselect__btns {
  display: flex;
  column-gap: 24px;
}