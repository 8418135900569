.checkbox-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin: -10px;
  width: max-content;

  .sub-text {
    margin-left: 14px;
    line-height: 20px;
    font-weight: 400;
    font-size: 14px;
    text-align: left;
  }

  &:hover, &:focus {
    .checkbox:not(.disabled) {
      box-shadow: 0 2px 6px rgba(0,0,0 , 0.25);
    }
  }

  .checkbox {
    border: 1px solid #AFAFAF;
    box-sizing: border-box;
    border-radius: 2px;
    width: 16px;
    height: 16px;
    transition: ease all .3s;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;


    &.checkbox__switch {
      width: 37px;
      height: 20px;
      background: #ccc;
      border-radius: 25px;
      position: relative;
      transition: background 0.3s;
    }

    &.checkbox-radio {
      border-radius: 50%;
      width: 18px;
      height: 18px;

      .checkbox__inner-point {
        border-radius: 50%;
        width: 14px;
        height: 14px;
      }
    }

    i {
      font-size: 14px;
      display: none;
    }

    &.disabled {
      background-color: $lightLightGrayColor;
    }

    &.active {
      background: $activeSecondColor;
      border-color: $activeSecondColor;

      i {
        display: flex;
        color: white;
      }

      &.checkbox__switch {
        background: $activeSecondColor;
      }

      .checkbox__switch-circle {
        left: calc( 100% - 16px - 1px ) ;
      }
    }
  }
}

.checkbox__inner-point {
  border: 2px solid white;
  width: 12px;
  height: 12px;
}

.checkbox__switch-circle {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 16px;
  height: 16px;
  background: white;
  border-radius: 50%;
  transition: left 0.3s;
}