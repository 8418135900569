$bgColor: #F9F9F9;
$blackColor: #292D38;
$blackSecondColor: #4A5267;
$activeColor: #2455D6;
$activeSecondColor: #5079E0;
$orangeColor: #FAB913;
$pinkColor: #F99781;
$pinkAnotherColor: #F0A4A4;
$greenColor: #1DB447;
$grayColor: #AFAFAF;
$lightGrayColor: #E1E1E1;
$lightLightGrayColor: #F6F6F6;
$whiteColor: white;
$lightBlueColor: #46D4F3;
$redColor: #D75A5A;
$violetColor: #E576F6;