
.app-logo {
  top: 34px;
  left: 40px;
  position: absolute;
  padding: 10px;
  margin: -10px;
  display: flex;
  align-items: center;
  z-index: 1;
  transition: box-shadow .2s ease;
  
  &.logo-title {
    letter-spacing: 4px;
    font-size: 18px;
    text-transform: uppercase;
    padding: 8px;
  }
}

.powered-by-merchery {
  position: absolute;
  bottom: 41px;
  left: 48px;
  display: flex;
  align-items: center;
  font-size: 13px;
  line-height: 18px;
  color: rgb(74, 82, 103);
  z-index: 1;

  span {
    margin-right: 4px;
    color: rgba(74, 82, 103, 0.7);
  }
  button {
    font-size: inherit;
    line-height: inherit;
  }
}

.sign-in-forms-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  color: $blackSecondColor;

  h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    margin-bottom: 24px;
    &::before {
      right: unset;
      left: 0;
      top: calc(100% - 2px);
    }
  }

  .merchery-label {
    margin-bottom: 12px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .blue-btn {
    &:hover, &:focus {
      box-shadow: 0px 2px 6px rgb(0 0 0 / 45%);
    }

    height: 48px;
    font-weight: 600;
    margin: 24px 0;
  }
}

.password-recovery-link {
  font-size: 14px;
  line-height: 19px;
  color: $activeSecondColor;

  &:focus, &:hover {
    text-shadow: 0 0 4px rgba($color: $activeSecondColor, $alpha: .3);
  }
}

.sign-in-form {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  width: 100%;
}

.recovery-done {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  width: 320px;

  h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    margin-bottom: 22px;
  }

  .text-div {
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 8px;
  }
}