.categories {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}

.categories__inner {
  display: flex;
  column-gap: 24px;

  .categories-tree {
    width: 268px;
  }
}

.categories-tree__wrapper {
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;background-color: white;
  color: #4A5267;
}

.category-page {
  display: flex;
  flex: 2 1;
  gap: 16px;
  flex-direction: column;

  .category-page__summary__description {
    margin-top: 24px;
  }
}

.category-page__summary {
  display: grid;
  grid: 
  "title title"
  "inputs dnd" 
  "desc desc"
  "navs navs" / 2fr 1fr;
  gap: 24px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 24px;
  padding-bottom: 32px;
}

.category-page__summary__title {
  grid-area: title;
}
.category-page__summary__inputs-wrapper {
  display: grid;
  grid-area: inputs;
  grid-template-rows: auto auto auto;
  gap: 16px 24px;

  .grid-element {
    &:nth-child(1) {
      grid-column: 1 / 3;
      grid-row: 1 / 2;
    }
    &:nth-child(2) {
      grid-column: 1 / 2;
      grid-row: 2 / 3;
    }
    &:nth-child(3) {
      grid-column: 2 / 3;
      grid-row: 2 / 3;
    }
    &:nth-child(4) {
      grid-column: 1 / 3;
      grid-row: 3 / 4;
    }
  }
}

.category-page__summary__navs {
  display: grid;
  grid-template-columns: 20% auto;
  column-gap: 16px;
  
  .grid-element {
    &:nth-child(1) {
      grid-column: 1 / 2;
    }
    &:nth-child(2) {
      grid-column: 2 / 3;
    }
  }
}

.category-page__summary__image {
  padding-top: 26px;
  height: 237px;
  grid-area: dnd;
}
.category-page__summary__description {
  grid-area: desc;
}

.description-text {
  word-break: break-word;
}

.category-page__summary__navs {
  grid-area: navs;
}

.category-page__characteristics {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #FFFFFF;
  width: 100%;
  position: relative; 
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
  
  .characteristics-top {
    border-bottom: 1px solid #E4E4E4;
    height: 61px;
  }

  .characteristic:first-child {
    margin-top: 24px;
  }
}

.characteristic-labels.category-page__characteristic-labels {
  padding: 16px 35px 24px;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
}

.possible-url {
  display: inline-flex;
  color: rgba($blackSecondColor, .70);
  position: absolute;
  top: calc(100% + 8px);
  cursor: copy;

  span {
    color: $blackSecondColor;
  }
}

.uuid-input__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.uuid-input__header-text {
  display: inline-flex;
  column-gap: 8px;
  align-items: center;
}

.uuid-input__header-generate-btn {
  color: #5079E0;
}

.categories-tree__single-option {
  padding: 8px 28px 8px 12px;
  border-bottom: 1px solid #E4E4E4;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.categories-tree__body {
  padding: 16px 16px 24px 12px;
}

.categories-tree__selected-item {
  border-radius: 4px;
  padding: 8px 16px;
  width: max-content;

  &:hover {
    background: rgba(74, 82, 103, 0.05);
    border-radius: 4px;
  }

  &:active, &:focus {
    background: rgba(76, 93, 250, 0.05);
    color: $activeColor;
  }
}

.categories-tree {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 0 268px;  
  max-height: 100%;
  background: #FFFFFF;
  padding: 15px 0 16px 0;

  .rc-tree .rc-tree-treenode {
    display: flex;
    align-items: center;


    .rc-tree-switcher-noop {
      display: none;
    }

    .rc-tree-icon__docu {
      display: none;
    }

    .rc-tree-icon__open {
      background-image: url('../../../img/arrow-down.png');
    }

    &.drop-target {
      background-color: unset;
    }

    &.drop-container, &.drop-target.drag-over, &.rc-tree-treenode-disabled {
      span.rc-tree-switcher {
        display: none;
      }
    }

    &.drop-container, &.drop-target.drag-over {
      .rc-tree-title {
        background-image: url('../../../img/add-plus-icon-blue.svg');
        background-repeat: no-repeat;
        background-position: left 4px top 50%;
      }
    }
    &.drop-container ~ .rc-tree-treenode {
      border-left: none;
    }

    &:first-of-type {
      margin-top: -35px;
      visibility: hidden;
    }

    &.dragging {
      transition: 0.01s;
      visibility: collapse;
      height: 34px;
      padding: 8px 15px;
      width: max-content;
      // width: 126px;
      background: #5079E0;
      border-radius: 4px;
      color: #FFFFFF;
      
      .category-item__context-menu-btn img {
        visibility: hidden;
      }
    }

    .rc-tree-node-content-wrapper {
      width: 100%;
      margin-right: 16px;
      height: unset;
      display: inline-flex;
      min-width: 0;
      align-items: center;

      &:hover {
        background: rgba(74, 82, 103, 0.05);
        border-radius: 4px;
    
        .category-item__context-menu-btn img {
          visibility: visible;
        }
      }
    }
  }
  .rc-tree-title {
    width: 100%;
    display: flex;

    & ~ div {
      // display: none;
      bottom: -3px !important;
      height: 6px !important;
      background-image: url('../../../img/row-separator.svg');
      background-repeat: no-repeat;
      background-color: transparent !important;
    }
  }
  .rc-tree-node-selected {
    background-color: unset;
    box-shadow: unset;
    opacity: unset;
    @extend .categories-tree__selected-item;
    color: $activeColor;
    background: rgba(76, 93, 250, 0.05);
    border-radius: 4px;
    
    .items-counter {
      color: $activeSecondColor;
      background-color: #dcdff6;
      pointer-events: none;
    }
  }
  .rc-tree .rc-tree-treenode span.rc-tree-switcher {
    width: 0;
    height: 12px;
    position: relative;
    background-image: none;
    left: 4px;
    margin: 0;
    z-index: 1;

    img {
      transition: transform .15s ease-in-out;
      padding: 5px;
      margin: -5px;
    }

    &.rc-tree-switcher_close img {
      transform-origin: center;
      transform: rotate((-90deg));
    }

  }
}
.categories-tree__wrapper--filters-mod .category-item__btn {
  padding-left: 12px;
}

.categories-tree__add-new-btn {
  font-size: 16px;
  color: $activeSecondColor;
}

.category-item {
  width: 100%;
  display: flex;
  padding-right: 12px;
  // column-gap: 16px;

  .items-counter {
    color: $blackSecondColor;
    background-color: #E4E4E4;
  }
}

.category-item__btn {
  display: flex;
  padding: 8px 12px 8px 24px;
  width: 100%;
  text-align: left;
  word-break: break-word;
  white-space: normal;
  align-items: center;
}

.category-menu {
  right: 0;
  width: auto;
  left: unset;

  .popup-group:not(:first-of-type) {
    border-top: 1px solid #E6E6E6;
  }

  .popup-element {
    padding-left: 12px;
    display: flex;
    gap: 12px;
  }
}

.category-item__context-menu-btn {
  display: flex;
  align-items: center;
  width: 16px;
  padding: 5px;
  margin: -5px;
  box-sizing: content-box;

  img {
    visibility: hidden;
  }
}

.category-tree-popup {
  max-height: calc(100vh - 185px);
  height: max-content;
}

.seo {
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.seo__header {
  padding-bottom: 24px;
  padding-top: 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #E4E4E4;
}

.seo__inner {
  padding-top: 16px;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  column-gap: 31px;

  .grid-element {
    grid-column: 1 / 3;
  }
  
  .seo_title {
    grid-row: 1;
  }
  .seo_desc {
    grid-row: 2;
  }
  .seo-checkbox {
    grid-row: 3;
    justify-content: flex-start;
  }
  .og_title {
    grid-row: 4;
    grid-column: 1 / 2;
  }
  .og_desc {
    grid-row: 5;
    grid-column: 1 / 2;
  }
  .og-dropzone {
    grid-row: 4 / 6;
    grid-column: 2 / 3;
    display: flex;
    flex-direction: column;
    max-height: 200px;
  }
}

@media(max-width: 1180px) {
  .category-page .category-page__summary__description {
    margin-top: 0;
  }
}