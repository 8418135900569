.order-page-delivery {
  grid-row: 5 / 7;
  grid-column: 1 / 2;
  background: #FFFFFF;
  font-size: 12px;
  line-height: 16px;

  & > div:not(.op-delivery-change) {
    padding: {
      left: 27px;
      right: 24px;
    };

    border-bottom: 1px solid #E4E4E4;
  }

  &.has-error {
    border: #d75a5a 1px solid;
    
    &::before {
      right: unset;
      left: 190px;
      top: 24px;
    }
  }
}
  
.op-delivery-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 63px;
  background: #FFFFFF;
}
  
.op-delivery-center {
  min-height: 62px;
  font-size: 12px;
  line-height: 16px;
  color: #292D38;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  .left {
    display: flex;
    align-items: center;

    img {
      height: 100%;
      object-fit: cover;
      margin-right: 30px
    }
  }

  & > button {
    @include btn;
    font: inherit;
  }

  span {
    margin-left: 20px;
    position: relative;

    div {
      position: absolute;
      left: -19px;
      top: 2px;
      width: 12px;
      height: 12px;
      border-radius: 2px;
    }
  }
}

.op-delivery-address {
  padding: {
    top: 20px;
    bottom: 19px;
  };
  display: grid;
  grid-template-columns: auto max-content;
  gap: 10px 20px;
  align-items: center;

  & > div:nth-child(2n) {
    text-align: right;
  }

  & > div:nth-child(-n + 2) {
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.045em;
    text-transform: uppercase;
  }
  

  & > div:nth-child(n + 3) {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;

    span {
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      color: #292D38;
      margin-right: 6px;
    }
  }
}

.op-delivery-dates {
  padding: 18px 24px;
  display: flex;
  justify-content: space-between;
  column-gap: 2%;
  border-bottom: 1px solid #E4E4E4;
}

.op-delivery-dates__left-inner {
  display: flex;
  column-gap: 60px;
}

.op-delivery-dates__right-inner {
  display: flex;
  justify-content: flex-end;
}

.delivery-note {
  display: flex;
  align-items: flex-end;
}

.delivery-note-number {
  color: #292D38;
  font-size: 13px;
  font-weight: 600;
  word-wrap: break-word;
  border-bottom: 0.50px black dotted;
}

.pvz-wrapper {
  grid-area: c;
}

.pvz-data {
  display: grid;
  grid-template-columns: auto max-content;
  grid-area: c;
  row-gap: 21px;

  & > div:nth-child(2n) {
    text-align: right;
  }

  .blue-text {
    color: $activeColor;
    border-bottom: dashed 1px $activeColor;
    margin-right: 13px;
    cursor: pointer;
  }
}

.delivery-address-inputs {
  display: flex;
  align-items: center;
  column-gap: 16px;

  .delivery-city-input {
    flex-basis: 196px;
  }

  .delivery-postcode-input {
    flex-basis: 86px;
  }

  .delivery-address-input {
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.op-delivery-cdek__city-dimensions {
  display: flex;
  flex-direction: row;
  column-gap: 24px;
  row-gap: 11px;
}

.op-delivery-cdek__city-dimensions__column,
.op-delivery-cdek__address-cost__column {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  justify-content: space-between;

  &.op-delivery-cdek__city-dimensions__column--city-column {
    flex: 1 0 auto;
    position: relative;
  }
}

.op-delivery-cdek__city-dimensions__inputs {
  display: flex;
  column-gap: 12px;
}

.op-delivery-cdek__city-dimensions__possible-cities.possible-values-popup {
  min-width: 370px;
  width: min-content;
  max-width: 100%;
  max-height: 200px;
  overflow-y: auto;
  top: 50%;
  transform: translate(0, -50%);
  left: calc(100% + 10px);

  .popup-element  {
    display: inline-flex;
    flex-wrap: nowrap;
    text-wrap: nowrap;
  }
}

.delivery-dimesions-presets__wrapper {
  display: flex;
  justify-content: space-between;

  .merchery-bubbles {
    column-gap: 20px;
  }
  .bubble {
    border: none;
    padding: 0;
  }
}

.op-delivery-cdek {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  padding: 19px 0 20px;
  align-items: flex-start;
  position: relative;

  &.sdek-widget {
    display: grid;
    grid-template-areas: 'a b'
    'c c';
    grid-template-columns: 48.5% 48.5%;
    align-items: center;
    column-gap: 3%;
  }

  // .sdek-btns {
  //   display: flex;

  //   button {
  //     height: 30px;

  //     &.white-btn {
  //       color: $activeSecondColor;
  //     }

  //     &:first-of-type {
  //       border-top-right-radius: 0;
  //       border-bottom-right-radius: 0;
  //     }

  //     &:last-of-type {
  //       border-top-left-radius: 0;
  //       border-bottom-left-radius: 0;
  //     }
  //   }
  // }
}

.cdek-tariffs__table {
  gap: 9px;
  width: 100%;

  .table__inner {
    background: transparent;
    border: 0;
    box-shadow: none;

    .table__row:last-of-type {
      border-bottom: 1px solid #E4E4E4;
    }
  }

  .table__header {
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0.1;
    text-transform: uppercase;
  }
}

.table__row.cdek-tariffs__row,
.table__header.cdek-tariffs__header {
  display: grid;
  grid-template-columns: auto 20% 20%;
  column-gap: 5%;
  border: none;

  .table__cell:last-of-type {
    padding-right: 16px;
  }
}

.table__row.cdek-tariffs__row {
  background: transparent;
  border: transparent 1px solid;
  border-top: 1px solid #E4E4E4;
  border-bottom: 1px solid transparent;
  box-shadow: none;

  &:first-of-type {
    border-top: 1px solid #E4E4E4;
  }

  .table__cell {
    padding: 10px 0;

    &:first-of-type {
      padding-left: 16px;
    }
  }

  &.active, &:hover {
    border-radius: 4px;
    border: 1px solid #E4E4E4;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: none;

    & + .table__row.cdek-tariffs__row:not(.active):not(:hover) {
      border-top-color: transparent;
    }
  }
}

.cdek-tariffs__tariff-name-cell.table__cell {
  column-gap: 8px;
}

.op-delivery-cdek__address-cost {
  display: flex;
  justify-content: space-between;
  width: 100%;
  column-gap: 24px;

  .op-delivery-cdek__address-cost__column {
    flex: 1 0 auto;

    &.op-delivery-cdek__address-cost__column--address {
      flex: 4 0 auto;
    }
  }

  .delivery-cost-input {
    flex: 0 1 auto;
  }
}

.op-delivery-extended {
  display: flex;
  flex-direction: column;
  row-gap: 13px;
  padding: 19px 0 20px;

  .open-widget-btn {
    justify-self: end;
  }

  .title-div {
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.045em;
    text-transform: uppercase;
    padding-top: 5px;
  }
}

.delivery-price-and-mode {
  display: flex;
  position: relative;
  justify-content: space-between;
  height: 20px;

  span:not(.currency-pt-sans) {
    font-size: 13px;
    line-height: 15px;
    color: $activeColor;
    border-bottom: dashed 1px $activeColor;
    display: inline-block;
    width: max-content;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    transition: ease box-shadow .2s, ease border .2s;
  }
}

.delivery-mode {
  cursor: pointer;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  column-gap: 3px;
  height: 100%;

  &:hover, &:focus {
    .blue-color {
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
      border-bottom-style: solid;
    }
  }
}

.general-popup.sdek-cities {
  width: 238px;
  left: 0;
  top: unset;
  bottom: 78px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);

  & > div {
    height: max-content;
    max-height: 250px;
    overflow-y: auto;
    
    & > div {
      position: relative;
      column-gap: 10px;
      display: grid;
      grid-template-areas: 'a a'
      'b c';

      .a {
        grid-area: a;
      }
    }
  }
}

.general-popup.delivery-tariff {
  width: max-content;
  min-width: 180px;
  min-height: 80px;
  left: unset;
  left: -10px;
  top: unset;
  bottom: calc(100% + 15px);

  & > div {
    height: max-content;
    max-height: 250px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    .delivery-tariff-grid {
      position: relative;
      column-gap: 10px;
      display: grid;
      grid-template-areas: 'a a'
      'b c';

      .a {
        grid-area: a;
      }
    }
  }
}

.delivery-tariff-row.popup-element {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding: 10px;
  row-gap: 3px;
  width: 100%;
}

.delivery-tariff-row-header {
  font-weight: 600;
  font-size: 12px;
}

.general-popup.pickpoint-tariff {
  width: 250px;
  left: unset;
  left: -10px;
  top: unset;
  bottom: calc(100% + 15px);

  & > div {
    height: max-content;
    max-height: 250px;
    overflow-y: auto;

    & > div {
      position: relative;
      display: grid;
      column-gap: 10px;
      grid-template-columns: auto max-content;
    }
  }
}

.pickpoint-mode {
  position: relative;
}

.open-widget-btn {
  @include btn;
  width: auto;
  height: 40px;

  &.pickup {
    min-width: 200px;
  }
}

.op-delivery-pickup {
  display: flex;
  padding: 19px 0;
  justify-content: space-between;

  .pickup-data {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .title-div {
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.045em;
    text-transform: uppercase;
  }

  .text-div {
    font-size: 12px;
    font-weight: 600;
  }
}

.pickup-data__right-side {
  position: relative;
}

.op-delivery-simple {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.to-orders-btn {
  font-size: 12px;
  line-height: 16px;
  opacity: 0.7;
  display: flex;
  align-items: center;
  padding: 5px;
  margin: -5px;
  margin-left: -22px;
  margin-bottom: 0;
  width: max-content;

  i {
    margin-right: 6px;
  }

  &:hover, &:focus {
    text-shadow: 0 0 10px rgba(36, 85, 214, 0.3);
    color: $activeSecondColor;
  }
}

.op-delivery-item {
  position: relative;

  .check-box-div {
    width: 16px;
    height: 16px;
    border: 1px solid #AFAFAF;
    box-sizing: border-box;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    transition: ease box-shadow .2s;
  }

  &.active {
    background: #F6F7FF;

    .check-box-div {
      position: relative;
      border: 1px solid $activeSecondColor;
      
      div {
        display: flex;
        width: 10px;
        height: 10px;
        background: $activeSecondColor;
        border-radius: 50%;
      }
    }
  }
}

.op-delivery-change-btns {
  display: flex;

  .blue-btn {
    height: 36px;
  }

  .white-btn {
    margin-right: 13px;
    height: 36px;
    color: $activeSecondColor;
    border: 1px solid rgba(81, 126, 242, 0.25);
  }
}

.op-delivery-item-header {
  padding: {
    left: 27px;
    right: 24px;
  };
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  height: 56px;
  align-items: center;
  border-bottom: 1px solid #E4E4E4;

  &:hover, &:focus {
    .check-box-div {
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
      border-color: $activeSecondColor;
    }
  }
}

.op-delivery-item-logo {
  height: 75%;
  display: flex;
  align-items: center;

  img {
    max-height: 100%;
    object-fit: cover;
  }
}

.op-delivery-item-check {
  display: flex;
  align-items: center;
}

.op-delivery-item-main {
  padding: {
    left: 27px;
    right: 24px;
  };
  border-bottom: 1px solid #E4E4E4;

  .blue-text {
    color: $activeColor;
    border-bottom: dashed 1px $activeColor;
    margin-right: 13px;
    cursor: pointer;
    transition: ease box-shadow .2s, ease border-bottom .2s;

    &:hover, &:focus {
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
      border-bottom-style: solid;
    }
  }
}

.op-delivery-pickpoint {
  display: grid;
  row-gap: 11px;
  column-gap: 20px;
  padding: 20px 0;
  grid-template-columns: repeat(3, minmax(max-content, auto));
  align-items: center;
 
  & > *:nth-child(3n) {
    text-align: right;
  }

  .title-div {
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.045em;
    text-transform: uppercase;
  }

  .pickpoint-btn {
    display: flex;
  }
}

.delivery-price {
  font-size: 14px;
}

.promo-clean,
.promo-create-btn {
  cursor: pointer;
  color: rgba(80, 121, 224, 1);
}

.no-code {
  color:#F47373
}

.pvz-wrapper {
  height: 600px;
}

.pvz-wrapper.hide {
  display: none;
}

.group-by-row {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.op-delivery-top-edit-btn {
  width: 16px;
  height: 16px;
  font-size: 16px;
  i {
    color: #AFAFAF;
  }

  &:hover, &:focus {
    i {color: $activeSecondColor;}
  }
}

.op-delivery-status {
  height: 25px;
  padding: 0 17px;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

@media(max-width: 1180px) {
  .to-orders-btn {
    margin-left: -5px;
  }
}