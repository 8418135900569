
.order-page {
  position: relative;
  display: flex;
  flex-direction: column;
}

.order-page__section {
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.order-page-container {
  display: grid;
  gap: 20px;
  grid-template-columns: minmax(0, auto) 269px;
  grid-template-rows: auto auto auto auto auto;
  min-width: 0;
  min-height: 0;
  overflow: unset;
}

.order-page-header {
  grid-row: 1 / 2;
  grid-column: 1 / 3;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    display: inline-block;
    font-size: 24px;
    line-height: 33px;
    margin-right: -4px;
  }

  span {
    font-size: 13px;
    line-height: 18px;
  }
}

.order-page-header__left-inner {
  display: flex;
  align-items: center;
  column-gap: 12px;
}

.order-page-change-bar {
  grid-column: 1 / 3;
  grid-row: 2 / 3;
  display: flex;
  justify-content: space-between;
  
  .select-btn {
    color: white;

    &.grayColor {
      background: #E4E4E4;
      color: $blackSecondColor;
    }
  }

  .sub-text {
    @include flex-center;
    color: $blackSecondColor;
    font-size: 27px;
    position: relative;
  }

  .done-btn-deputy {
    width: 30px;
    height: 30px;
    margin-right: 14px;
  }

  .paid-select, .status-select {
    @include flex-center;
    
    min-width: 121px;
    width: max-content;
    border-radius: 4px;
    height: 25px;
    cursor: pointer;
    position: relative;
    white-space: nowrap;
    position: relative;

    .btn-clickable-zone {
      padding: 0 33px 0 16px;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 11px;
      line-height: 15px;

      i {
        font-size: 12px;
        position: absolute;
        right: 4px;
      }
    }

    &::after {
      content: '';
      right: 18px;
      border-right: 1px solid rgba(255, 255, 255, 0.3);
      width: 0;
      height: 100%;
      position: absolute;
    }
  }
}

.order-page-change-bar__left-inner,
.order-page-change-bar__right-inner {
  display: flex;
  column-gap: 12px;
  align-items: center;
}

.square-btn {
  width: max-content;
  height: 24px;
  font-size: 24px;
  color: #AFAFAF;
  display: flex;
  align-items: center;
  justify-content: center;
  // margin-right: 14px;
  position: relative;
  transition: ease box-shadow 0.2s;

  &:hover, &:focus {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  }

  &.done-btn {
    border: 1px #AFAFAF solid;
    border-radius: 6px;
  }

  // &:not(.done-btn) > div:not(.general-popup):not(.add-manager-24),
  // &:not(.done-btn) > div:not(.general-popup) > div:not(.add-manager-24) {
  //   height: 100%;
  //   width: 100%;
  //   display: flex;
  //   align-items: center;

  //   & > .manager-icon {
  //     min-width: 30px;
  //     width: 30px;
  //     height: 30px;
  //     font-size: 16px;
  //   }

  //   & > span {
  //     font-weight: 600;
  //     font-size: 14px;
  //     line-height: 18px;
  //     margin-left: 8px;
  //   }
  // }
}

.order-page__refund {

  .select-btn {
    position: relative;
  }

  .select-btn-inner {
    padding: 4px 18px;
    border-radius: 4px;
    text-transform: uppercase;
  }
}

.order-page__refund-btn {
  display: flex;
  column-gap: 8px;
  margin: -10px;
  padding: 10px 15px;
  
  font-size: 11px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.1px;
  text-transform: uppercase;
  opacity: 0.8;
  color: #4A5267;

  &.disabled {
    opacity: 0.4;
  }
}

.order__external-btn {
  letter-spacing: 0.1em;
  text-transform: uppercase;
  opacity: 0.8;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  display: flex;
  padding: 10px;
  margin: -10px 14px -10px -10px;
  transition: ease box-shadow 0.2s;

  &:focus, &:hover {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  }

  &:last-of-type {
    margin-right: 0;
  }
}

.order-page-buttons {
  display: flex;
  align-items: center;
  column-gap: 10px;
  padding-left: 18px;

  i {
    font-size: 16px;
    margin-right: 8px;
  }

  .order__external-btn {
    align-items: center;
  }
}

.send-letter-popup {
  display: flex;
  row-gap: 8px;
}

.grid-child-table {
  grid-row: 3 / 4;
  grid-column: 1 / 2;
  height: max-content;
  overflow: unset;
}

.order-page-side-panel {
  grid-row: 3 / 7;
  grid-column: 2 / 3;
  overflow-wrap: anywhere;
  background: #FFFFFF;
  height: max-content;
  position: relative;

  & > div {
    padding: 18px 20px 20px 18px;

    &:not(:last-of-type) {
      border-bottom: 1px solid #E4E4E4;
    }

    & > span {
      color: $activeSecondColor;
      margin: 9px 0 4px;
      display: inline-block;
    }

    h4 {
      font-size: 10px;
      line-height: 14px;
      letter-spacing: 0.045em;
      text-transform: uppercase;
      color: $blackSecondColor;
    }

    & > div:not(.client-edit-header) {
      font-size: 13px;
      line-height: 18px;
      color: #000000;
    }
  }
}

.order-page-side-panel-edit-btn {
  display: inline-flex;
  font-size: 16px;
  color: #AFAFAF;
  text-align: right;
  flex-basis: auto;

  &:hover, &:focus {
    color: $activeSecondColor;
  }
}

.order-page-side-panel-delete-btn {
  position: absolute;
  display: inline-flex;
  top: 20px;
  right: 20px;
}

.order-page-side-panel-main {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
}

.order-page-side-panel-contacts span {
  font-size: 14px;
  line-height: 19px;
}

.order-page-side-panel-purchases-history {
  display: flex;
  flex-direction: column;

  & > div:first-of-type {
    cursor: pointer;
  }

  .returns-and-buyout  { 
    margin-bottom: 6px;

    & > div {
      flex-basis: 40%;
    }
  }

  & > div:not(.total-orders-sum) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .special-span {
    color: $activeSecondColor;

    &:hover, &:focus {
      text-shadow: 0 0 8px rgba($color: $activeSecondColor, $alpha: .5);
    }
  }

  .total-orders-sum {
    margin: 16px 0 24px;
  }

  .icon-div {
    height: max-content;
    width: max-content;
    transition: ease .1s all;
    user-select: none;
  }

  .title-div {
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.045em;
    text-transform: uppercase;
    margin-bottom: 4px;
    color: $blackSecondColor;
  }

  .text-div {
    font-size: 13px;
    line-height: 18px;
  }

  &.opened {
    .icon-div {
      transform: rotate(90deg);
    }
  }
}

.purchases-history-show-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover, &:focus {
    h4 {
      color: $activeSecondColor;
    }
  }
}

.order-page-side-panel-contacts {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.clipboards-btn {
}

.clipboards {
  background: url('../img/clipboards.svg') no-repeat 50% 50%;
  width: 24px;
  min-width: 24px;
  height: 24px;
}

.add-manager-24 {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: url('../img/add-manager-24.png') no-repeat 50% 50%;
}

.table-items {
  position: relative;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  border-radius: 4px;

  .table-item {
    border-bottom: 1px solid #E4E4E4;
  }

  .table-item > div {
    vertical-align: middle;

    &.op-check {
      font-size: 14px;
    }

    &.op-quantity, &.op-weight, &.op-price, &.op-total {
      white-space: nowrap;
    }

    &.op-title {
      width: 33%;
    }

    &.op-title, &.op-quantity, &.op-image {
      text-align: left;
    }

    &.op-price, &.op-total {

      &.op-ms-cell {
        text-align: unset;
        font-size: 12px;
        white-space: unset;
      }
    }

    &.op-weight {
      text-align: center;
    }
  }

  //.op-check, tr th.op-check {
  //  width: 69px;
  //}

  //tr td.op-image, tr th.op-image {
  //  width: 70px;
  //}

  .table-item > div {
    padding: 12px 3px;
    color: #292D38;
    font-size: 14px;
    line-height: 19px;

    &.op-image:not(:first-of-type) {
      padding-right: 0;
      padding-left: 0;
    }
  
    &.op-image {
      & > div {
        @include flex-center;
        width: 56px;
        height: 56px;
  
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    &.op-title {
      & > div div:nth-child(2){
        font-size: 12px;
        line-height: 16px;
        color: $blackSecondColor;
        margin: 2px 0 4px;
      }

      & > div div:nth-child(3){
        font-size: 12px;
        line-height: 16px;
      }
    }
  }

  .table-item > div {
    &.op-title {
      width: 100%;
    }
  }

  .op-check {

    .checkbox {
      width: 16px;
      height: 16px;
      border: 1px solid $lightGrayColor;
      border-radius: 2px;
      cursor: pointer;
      display: inline-block;

      i {
        font-size: 14px;
      }

      &:disabled {
        cursor: default;
      }

      &:not(:disabled) {
        &:focus, &:hover {
          border: 1px solid #b5b9c4;
        }
      }
    }
  }

  .op-price {
    min-width: 60px; 
  }

  .op-weight {
    min-width: 58px;
  }

  .op-quantity {
    min-width: 53px;
  }
}

.table-items-header.table-item {
  & > div {
    padding: 0 3px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;
    text-align: left;

    &.op-image > div {
      @include flex-center;
      width: 56px;
    }
  }
}

.order-items .row-item, .order-items__header .row-item {
  padding: 16px 24px;
  grid-template-columns: 20px 56px auto minmax(70px, 15%) minmax(80px, max-content) minmax(53px, max-content) minmax(70px, 15%);
  gap: 2%;
  display: grid;

  .row-item__price {
    justify-content: right;
  }
}

.order-items__header {
  .row-item { flex: 1 1 auto; }
  .row-item.selected {
    grid-template-columns: 20px auto;
  }

  display: flex;
  align-items: center;
  min-height: 52px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #4A5267;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  justify-content: stretch;
}

.selected-items-menu {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 13px;
  justify-content: space-between;
  text-transform: none;
  letter-spacing: normal;
  font-weight: 600;
  padding-right: 24px;

  i {
    margin-right: 8px;
  }
}

.selected-items-control {
  display: flex;

  & > button {
    font-size: 13px;
    font-weight: 600;
    margin-left: 32px;
    padding: 10px;
    margin: -10px;
    margin-left: 22px;
    gap: 0;
  }
}

.op-footer {
  display: flex;
  align-items: center;
  // grid-template-columns: max-content max-content max-content auto;
  padding: 10px 24px 17px;

  &.op-footer--disabled {
    padding-top: 17px;
  }
}

.op-footer-btns {
  display: flex;
  column-gap: 12px;
  flex-wrap: wrap;
}

.order-total-price {
  font-size: 14px;
  line-height: 19px;
  color: #292D38;
  display: flex;
  font-weight: 600;
  justify-content: flex-end;
  flex-grow: 1;
  flex-shrink: 0;
  height: 100%;
  align-items: center;
  position: relative;
}

.order-total-price-container {
  position: relative;
  display: flex;
  align-items: center;
  column-gap: 5px;
  height: 100%;
  padding: 10px;
  margin: -10px;

  & > div {
    column-gap: 3px;
    border-bottom: dashed 1px #292D38;
    display: inline-flex;
  }
  &:focus, &:hover {
    color: $activeSecondColor;
    & > div {
      border-bottom: solid 1px $activeSecondColor;
    }
  }
}

.general-popup.order-total-price-popup {
  position: absolute;
  height: 72px;
  border-radius: 4px;
  bottom: calc(100% - 10px);
  top: unset;
  left: unset;
  bottom: 100%;
  right: -30px;
  width: max-content;

  & > div {
    display: flex;
    column-gap: 8px;
    height: 100%;
    align-items: center;
    padding: 0 23px 0 14px;
    
    & > div { 
      display: flex;
      flex-direction: column;
    }
  }

  .title-div {
    font-size: 10px;
    letter-spacing: 0.045em;
    text-transform: uppercase;
    font-weight: 400;
  }

  .text-div {
    font-weight: 500;
    font-size: 14px;
  }

  .sub-text {
    margin-top: 18px;
  }
}

.order-page-btn {
  height: 36px;
  padding: 0 13px;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: $activeSecondColor;
  border: 1px solid rgba(81, 126, 242, 0.25);
  box-sizing: border-box;
  border-radius: 4px;
  transition: ease box-shadow 0.2s;

  &:hover, &:focus {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  }

  &.discount-promo {
    border: 1px solid transparent;
    background: rgba(76, 93, 250, 0.07);
    justify-content: unset;
    gap: 4px;

    &:hover, &:focus {
      border: 1px solid rgba(81, 126, 242, 0.25);
    }
  } 

  &.disabled {
    opacity: 0.7;
    border: 1px solid transparent;

    &:hover, &:focus {
      opacity: 1;
    }
  }
}

.order-page-empty-order {
  background: #FFFFFF;
  height: 66px;

  grid-row: 3 / 4;
  grid-column: 1 / 2;

  .inner-wrapper-empty-order {
    width: 100%;
    height: 100%;
    padding: 0 24px 0 27px;
    grid-row: 3 / 4;
    grid-column: 1 / 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // border: 1px solid rgba(0, 0, 0, 0.1);
    // box-sizing: border-box;
    // box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
    // border-radius: 4px;

    &.has-error {
      border: #d75a5a 1px solid;
      
      &::before {
        right: unset;
        left: 110px;
        top: unset;
      }
    }
  }

  & .title-div {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
  }
}

.select-category .select-item {
  width: 100%;
  padding: 10px 5px 10px 12px;
  cursor: pointer;
  text-align: left;
  color: $blackSecondColor;   
  word-break: break-all;

  &.all-categories, &.without-category {
    padding: 12px;
    border-bottom: 1px solid #E6E6E6;
  }
}

.select-item-wrapper {
  display: flex;
  position: relative;
}

.select-category .category-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.select-category {
  
  .icon-wrapper {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;

    &:hover, &:focus {
      background: $activeSecondColor;
      
      i {
        color: white;
      }
    }

    &.hided-subcategories i {
      transform: rotate(-90deg);
    }
  }

  i {
    width: 16px;
    height: 16px;
    color: #AFAFAF;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
  }

}

.order-page .add-manager-btn {
  width: max-content;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 50%;
  width: 25px;
  height: 25px;

  & > button {
    // display: flex;
    // flex-direction: row;
    // align-items: center;
    // column-gap: 14px;
    // transition: ease box-shadow 0.2s;
  
    &:hover, &:focus {
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
    }
  }
}

.order-page-change-bar__refund-status {
  background: #4A5267;
  border-radius: 4px;
  padding: 4px 18px;
  height: 25px;

  .refund-status__inner {
    text-transform: uppercase;
    color: #FFF;
  }
}

.discount-header h2 {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 15px;
}

.items-in-order-body {
  display: flex;
  flex-direction: column;
  height: max-content;
  max-height: 390px;
  overflow-y: auto;
  border-top: 1px solid #E4E4E4;
  border-bottom: 1px solid #E4E4E4;

  & > svg {
    width: 100%;
  }
}

.may-be-added-item {
  border-bottom: 1px solid #E4E4E4;
  height: 97px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #292D38;

  &.disabled {
    opacity: 0.6;
    background-color: rgba(0, 0, 0, 0.15);
    pointer-events: none;
  }

  &.with-variants {
    flex-direction: column;
    height: unset;
    align-items: stretch;
  }

  &.variant {
    padding: 20px 30px 20px 57px;
    display: none;
  }

  &:last-of-type {
    border-bottom: none;
  }

  .iio-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 97px;
    width: 100%;
    padding: 20px 30px 20px 27px;
    border-bottom: 1px solid transparent;
    max-height: 97px;
    cursor: pointer;

    &:hover, &:focus {
      .iio-show-attributes {
        text-shadow: 0 0 8px;
      }
    } 
  }

  .left, .right {
    align-items: center;
    display: flex;
    // flex-basis: 40%;
  }

  .right {
    column-gap: 29px;
    justify-content: flex-end;
  }

  .iio-show-attributes {
    padding: 8px;
    margin: -8px;
    margin-right: 16px;
    i {
      transform: rotate(-90deg);
    }

    div {
      @include flex-center;
      width: 16px;
      height: 16px;
      min-width: 16px;
    }
  }

  .iio-check {
    cursor: pointer;
    padding: 8px;
    margin: -8px;
    margin-right: 16px;

    div {
      @include flex-center;
      border: 1px solid #AFAFAF;
      width: 16px;
      height: 16px;
      border-radius: 2px;
      min-width: 16px;
      transition: ease box-shadow 0.2s;
    }

    &:hover div,
    &:focus div {
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    }

    i {
      display: none;
    }
  }

  .iio-image {
    @include flex-center;
    margin-right: 18px;
    width: 56px;
    height: 56px;

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: cover;
    }
  }

  .iio-title {
    display: flex;
    flex-direction: column;
    max-width: 270px;
    font-size: 14px;
    line-height: 19px;
  }

  .iio-title_not-exist {
    font-size: 12px;
  }

  .iio-total {
    font-size: 14px;
    line-height: 19px;
    width: max-content;
    text-align: right;

    .iio-input {
      width: 80px;
    }
  }

  .iio-input {
    @include border-light-gray;
    background: #FFFFFF;
    border-radius: 4px;
    min-width: 39px;
    box-sizing: border-box;
    height: 30px;
    margin-right: 6px;
    padding: 0 8px;
    font-size: 14px;
    line-height: 19px;

    &.disabled {
      background-color: transparent;
    }
  }

  .iio-quantity {
    .iio-input {
      width: 50px;
    }
  }

  .iio-vendor-code {
    font-size: 12px;
    line-height: 16px;
  }

  &.selected {
    background: #F6F7FF;

    .iio-check {
      div {border-color: $activeSecondColor;}
      
      i {display: unset;color: $activeSecondColor;}
    }
  }

  &.shown-attr {
    .iio-show-attributes {
      i {
        transform: none;
      }
    }
    
    .iio-header {
      border-bottom: 1px solid #E4E4E4;
    }

    .variant {
      display: flex;
    }
  }
}

.attr-container {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  line-height: 16px;

  > div {
    display: flex;
    align-items: center;
  }

  .value-div {
    @include border-light-gray;
    padding: 1px 3px 3px 3px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #FFFFFF;
    border-radius: 4px;
    margin-left: 8px;

    i {
      margin-left: 10px;
    }
  }
}

.items-in-order .row-item__price {
  justify-content: right;
}

.general-popup.items-in-order {
  position: fixed;
  top: 50%;
  width: 820px;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-bottom: 30px;
  z-index: 2;
}

.items-in-order-footer {
  height: 75px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
}

.items-in-order-btns {
  display: flex;

  .blue-btn {
    height: 40px;
    width: 148px;
  }

  .white-btn {
    width: 148px;
    margin-right: 13px;
    height: 40px;
    color: $activeSecondColor;
    border: 1px solid rgba(81, 126, 242, 0.25);
  }
}

.general-popup.client-edit {
  position: fixed;
  top: 140px;
  max-height: calc(100vh - 280px);
  overflow-y: auto;
  left: 50%;
  transform: translate(-50%);
  padding: 25px 0 0;

  width: 820px;
}

.searched-clients__wrapper {
  margin-top: -1px;
}

.client-edit__content--padding {
  padding-left: 27px;
  padding-right: 27px;
}

.client-edit-header {
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
}

.client-edit_search-inner {
  border-bottom: #E4E4E4 1px solid;
  padding-bottom: 15px;
  padding-top: 15px;
}

.searched-client {
  height: 48px;
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 2fr 1fr 2fr;

  border: #e4e4e4 solid 0;
  border-bottom-width: 1px;
  &:first-of-type {
    border-top-width: 1px;
  }
}

.client-edit__create-new {
  @extend .searched-client;

  height: 55px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.searched-client__name {text-align: left;}
.searched-client__phone {text-align: left;}
.searched-client__email {text-align: left;}

.searched-client--clickable {
  cursor: pointer;
  &:hover, &:active {
    background-color: rgba(76, 93, 250, 0.05);
  }
}

.client-edit-inputs-container {
  padding-top: 16px;
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 12px;

  .merchery-label {
    height: 48px;
  }
}

.client-edit-inputs__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
}

.client-edit-footer {
  display: flex;
  justify-content: space-between;
  background-color: #FBFBFC;
  padding-top: 27px;
  padding-bottom: 27px;
  
  & .my-btn {
    height: 40px;
    width: 186px;
    display: flex;
    padding: 0 50px;
  }
}

.client-edit-footer__right {
  display: flex;
  column-gap: 15px;
}

.discount-promo-header {
  display: flex;
  align-items: center;

  h2 {
    font-weight: 600;
    font-size: 18px;
  }
}

.cancel-create {
  display: flex;
  margin-right: 16px;
}

.row-item, .table-item {

  // &.focused {
  //   background-image: linear-gradient(90deg, hsl(223deg 55% 88% / 37%), white 15%, white 85%,  hsl(223deg 55% 88% / 37%));
  //   transition: 0.3s ease background;
  // }

  .op-check i {
    display: none;
    color: $activeSecondColor;
  }

  &.selected-variants .op-check .checkbox {
    border: $activeSecondColor 1px solid;
  }
}

.active-remain {
  color: #D75A5A;
}

// .items-in-order-container .row-item__count-unit {
//   display: flex;
//   align-items: center;
//   vertical-align: middle;
// }

.order-add-items-rows--template.row-item {
  display: grid;
  grid-template-columns: 16px 56px auto 16% 10% minmax(12%, 16%);
  padding-left: 24px;
  padding-right: 24px;
  column-gap: 2%;

  &.order-add-items-rows--variant {
    grid-template-columns: calc(37px - 2%) 16px 56px auto 16% 10% minmax(12%, 16%);
  }
}


.add-items-rows--variants-template.row-item {
  display: grid;
  grid-template-columns: 56px auto 42%;
  padding-left: calc(39px + 2%);
  padding-right: 24px;
  column-gap: 2%;
}

.row-item__external-id {
  justify-content: center;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.row-item.disabled {
  .row-item__count {
    
  }
}

.row-item__count {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 6px;
}

.add-items-count .merchery-label__input {
  height: 30px;
  min-width: 52px;
  padding: 0 8px 0 8px;
}

.general-popup.confirm-window {
  width: 360px;
  padding: 26px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  color: $blackSecondColor;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 26;

  h2 {
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 0;
    font-weight: 600;
  }

  & > span {
    margin: 0 0 10px;
    font-size: 13px;
    line-height: 18px;
    display: inline-block;
    font-weight: 400;
  }

  .confirm-window-btn {
    display: flex;
    justify-content: space-between;
  }

  .confirm-window-btn button {
    &.red-btn {
      padding: 0;
    }

    &.blue-btn {
      padding: 0;
    }

    font-weight: 600;
    width: 148px;
    justify-content: center;
  }

  .confirm-window-close-btn {
    position: absolute;
    top: 7px;
    right: 7px;
    color: $grayColor;
    opacity: 0.7;
    font-size: 20px;
    cursor: pointer;
  }

}

.row-gap-15 {
  row-gap: 15px;
}

.btn-clickable-zone {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  transition: ease box-shadow 0.2s;

  &:focus, &:hover {
   box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  }
}

.general-popup.change-items {
  width: 820px;
}

.general-popup.clients-popup {
  width: 50%;
  left: unset;
  right: 0;
  top: -110px;

  & > div {
    height: 155px;
    overflow-y: scroll;
  }

  .sub-div {
    font-size: 11px;
  }
}

.general-popup.manager .client-popup-wrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.dimensions {
  display: flex;
  column-gap: 3.5%;
  & > * {
    flex-basis: 31%;
  }
}

.order-add-items__no-remain {
  color: $redColor;
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
}

.order-add-items__has-remain {
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
}

@media (max-width: 1180px) {
  .order-page-buttons {
    padding-left: 10px;
  }
  .order-page-container {
    display: flex;
    flex-direction: column;
  }

  .order-page-side-panel {
    .order-page-side-panel-main, 
    .order-page-side-panel-contacts,
    .order-page-side-panel-purchases-history {
      padding: 14px 24px 15px 27px;
      font-size: 12px;

      & > div {
        font-size: 11px;
      }

      span {
        font-size: 12px;
      } 

      .text-div {
        font-size: 12px;
      }
    }
  }
  
  .order-total-price {
    font-size: 12px;
  }

  .table-items .op-footer > button {
    font-size: 11px;
  }

  .order-page-change-bar .square-btn:not(.done-btn) > div:not(.general-popup) > div:not(.add-manager-24) > span {
    font-size: 12px;
  }
}

.order-page__payment {
  display: flex;
  grid-row: 4 / 5;
  grid-column: 1 / 2;
  flex-direction: column;
}

.order-page__payment__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 63px;
  padding: 0 24px;
  border-bottom: 1px solid #E4E4E4;
}

.order-page__payment__body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  padding: 0 24px;
}

.order-page__paymethod {
  display: flex;
  column-gap: 8px;
  align-items: center;
}

.order-page__payment-sum {
  &.success {
    color: #1DB447;
  }
}

.order-page__refunds {
  display: flex;
  grid-row: 7 / 8;
  grid-column: 1 / 2;
  flex-direction: column;
  row-gap: 12px;
}

.order-page__refund__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 63px;
  padding: 0 24px;
  border-bottom: 1px solid #E4E4E4;
}

.order-page__refund-status {
  text-transform: uppercase;
  color: #FFF;
  padding: 4px 18px;
  background: #1DB447;
  border-radius: 4px;
}

.order-page__refund__body .row-item {
  padding: 16px 24px;
  grid-template-columns: 56px auto minmax(53px, max-content) minmax(70px, 15%);
  gap: 2%;
  display: grid;
}

.order-page__refund__footer {
  display: flex;
  justify-content: space-between;
  padding: 18px 24px;
  border-bottom: 1px solid #E4E4E4;
}

.order-page__refund-total {
  line-height: 14px;
}

.order-page__refund-delivery__footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  padding: 0 24px;
}

.refund-delivery {
  padding: 18px 24px;
  display: flex;
  justify-content: space-between;
  column-gap: 2%;
  border-bottom: 1px solid #E4E4E4;

  &__row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 9px;

    &.refund-received {
    }

    &.refund-sent {
    }

    &.return-duration {
      .date {
        color: #1DB447;
      }
    }

    &.arrived-in-store {
    }

    &.processed {
      display: flex;
      align-items: flex-end;
    }

    .status {
      color: #4A5267;
      font-size: 10px;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 0.45;
      word-wrap: break-word;
    }

    .date {
      color: #292D38;
      font-size: 13px;
      font-weight: 600;
      word-wrap: break-word;
    }
  }
}

.order-page__check {
  color: $activeSecondColor;
}

.order-page__paymethod__name {
  color: $blackColor;
}

.general-popup.refund-registration {
  width: 820px;
  padding: 0;
}

.refund-registration__header {
  padding-top: 24px;
  padding-bottom: 24px;
}

.refund-registration__footer {
  display: flex;
  justify-content: space-between;
  padding-top: 24px;
  padding-bottom: 32px;
  background: #FBFBFC;

  .text-div {
    display: flex;
    align-items: center;
  }
}

.refund-registration__popup-element {
  padding-left: 28px;
  padding-right: 28px;

  &:not(:last-of-type) {
    border-bottom: #e4e4e4 1px solid;
  }
}

.refund-registration__items {
  height: max-content;
  max-height: 390px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  .row-item {
    padding: 16px 24px;
    grid-template-columns: 20px 56px auto 12% 12% 12%;
    gap: 2%;
    display: grid;
  }
  
  .add-items-count .merchery-label__input {
    height: 42px;
  }
}

.refund-registration__cause {
  display: flex;
  column-gap: 24px;

  padding-top: 24px;
  padding-bottom: 32px;
}

.refund-registration__cause-type__wrapper {
  flex-shrink: 2;
}

.refund-registration__cause-description__wrapper {
  flex-shrink: 1;
}

@media print {
  .hide-for-print {
    display: none !important;
  }
}