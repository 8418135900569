.search {
  .merchery-label__input {
    padding-left: 38px;
  }
}

.input-search-icon {
  position: absolute;
  color: $blackSecondColor;
  opacity: 0.7;
  top: 50%;
  transform: translate(0, -50%);
  left: 11px;
  font-size: 16px;
}