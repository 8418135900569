.settings-page__wrapper.site-settings {
  display: flex;
  flex-direction: column;
}

.settings-page__one-setting {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding: 8px 12px;
}

.setting-module__group {
  border-radius: 4px;
  border: 1px solid #E4E4E4;
  display: flex;
  flex-direction: column;
  background: #FFF;
}

.setting-module__group-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #E4E4E4;
  padding: 22px 24px;
}

.setting-module__group-header__btn {
  padding: 7px;
  margin: -7px;

  i {
    font-size: 16px;
    opacity: 0.5;
  }

  &.arrow-btn {
    border: #E4E4E4 1px solid;
    border-radius: 4px;
    transform: rotate(180deg);
    background-color: rgba(#E4E4E4, .15);
    height: 18px;
    box-sizing: content-box;
  }
}

.setting-module__group-body {
  padding: 22px 24px 32px;
  flex: 1 1;
}

.setting-module {
  color: $blackSecondColor;
}

.site-settings {
  display: grid;
  gap: 16px;
  grid-template-columns: auto 288px;
  grid-template-areas: "summary tariff"
    "requisites file-storage"
    "taxes .";
}

.site-settings__summary {
  grid-area: summary;
}

.settings__tariff {
  grid-area: tariff;
}

.site-settings__requisites {
  grid-area: requisites;
}

.site-settings__requisites__inner {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.site-settings__file-storage {
  grid-area: file-storage;
  align-self: start;
}

.site-settings__misc {
  grid-area: taxes;
}

.site-settings__summary__inner {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  column-gap: 22px;
}

.site-owner__data {
  display: flex;

  .site-settings__group-column {
    flex: 1 1 33%;
  }
}

.site-settings__group-column {
  display: flex;
  flex-direction: column;
  gap: 8px;

  h4 {
    @extend .wide-text-font-xxs;
    opacity: .8;
  }
  
  span {
    @extend .header-font-m;
  }
}

.setting-module__group--edit {
  .site-owner__data {
    gap: 20px;
    flex-direction: column;
    flex: 0 2 100%;
  }

  .site-settings__group-column {
    h4 {
      @extend .header-font-s;
      text-transform: none;
      opacity: 1;
    }
  }

  .site-settings__summary__inner {
    flex-direction: row;
  }
  
  .site-logo {
    flex: 0 0 260px;
  }


  .site-address {
    gap: 20px;
    flex-direction: column;
  }
}

.setting-module__group--edit .site-logo {
  flex-direction: column;
  row-gap: 46px;
  justify-content: space-between;
}

.site-logo {
  flex: 1 1;
  display: flex;
  align-items: stretch;
  column-gap: 3.3%;
  padding-top: 26px;

  & > * {
    flex: 0 1 30%;

  }
}

.setting-module__group--edit .site-logo__inner {
  max-width: 160px;
  max-height: 160px;
  width: 100%;
  height: 100%;
}

.site-logo__inner {
}

.setting-module__group--edit .site-logo__dropzone {
  flex: 1 1 auto;
  max-height: 129px;

  .dropzone {
    width: 260px;
  }
}

.site-logo__dropzone {
  display: flex;
}

.setting-module__group--edit .site-logo__favicon {
  max-height: 42px;
  flex: 0 1 42px;
}

.site-logo__favicon {
  display: flex;

  .without-files {
    column-gap: 13px;
    flex-direction: row;
  }
  .without-files h4 {
    padding: 0;
  }

  .dropzone.has-error::before {
    top: unset;
    bottom: -20px;
  }
}

.site-logo__picture {
  width: 100%;
  max-height: 100%;
}

.site-address {
  display: flex;
  
  .site-settings__group-column {
    flex: 1 1 33%;
  }
}

.site-requisites {
  display: grid;
  grid-template-columns: auto 33.5%;
  gap: 20px;
}

.site-tariff__row {
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  border-bottom: 1px solid #E4E4E4;

  &:first-of-type {
    padding-top: 0;
  }

  &:last-of-type {
    border-bottom: none;
    padding-bottom: 0;
  }
}

.site-settings__file-storage__inner {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.file-storage__usage-and-limit {
  display: flex;
  row-gap: 8px;
  flex-direction: column;
}

.file-storage__usage-and-limit__row {
  display: flex;
  justify-content: space-between;
}

.file-storage__slider .slider {
  height: 22px;
  border: 1px solid #E4E4E4;
  border-radius: 4px;

  .track {
    height: 100%;

    &:first-of-type {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:last-of-type {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}

.site-settings__misc__inner {
  display: flex;
  column-gap: 20px;
}