
.merchery-header {
  background-color:  $whiteColor;
  box-shadow: 0 2px 6px rgba(#000, 0.05);
  z-index: 1;
}

.merchery-header-inner {
  position: relative;
  display: flex;
  height: 60px;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 47px 0 14px;
}

.merchery-header_left-inner {
  height: 100%;
  display: flex;
  align-items: center;
  flex: 1 1 auto;
}

.merchery-header_right-inner {
  height: 100%;
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  justify-content: flex-end;
  column-gap: 12px;
}

.nav-menu-btn {
  display: none;
}

.merchery-header-search {
  background-color:  $lightLightGrayColor;
  width: 216px;
  height: 36px;
  border-radius: 4px;
  display: block;
  position: relative;

  input {
    @include main-input;
    
    background-color: transparent;
    border: none;
    height: 100%;
    padding-left: 38px;
    box-sizing: border-box;
  }
}

.merchery-header-user {

  .user-circle {
    @include flex-center;
    width: 32px;
    height: 32px;
    color: $whiteColor;
    border-radius: 50%;
    background-color: $pinkAnotherColor;
    margin-right: 11px;
  }

  .user-name-and-post {
    padding-right: 22px;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;

    &::after {
      position: absolute;
      content: '';
      background: url('../img/arrow-down.png') no-repeat 50% 50%;
      top: 50%;
      right: 2.4px;
      transform: translate( 0, -50%);
      width: 12px;
      height: 12px;
    }
  }
}

.user-name {
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
}

.user-post {
  font-size: 11px;
  line-height: 15px;
  text-align: left;
}

.merchery-header-user-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.sign-out-btn {
  font-weight: 500;
  color: rgb(244, 115, 115);
}


@media(max-width: 1180px) {
  .nav-menu-btn {
    display: unset;
    font-size: 18px;
    color: $blackSecondColor;
    opacity: 0.7;
    cursor: pointer;
    padding: 10px;
    margin: -10px;
    z-index: 2;
    transition: all ease .3s;

    &:hover, &:focus {
      text-shadow: 0 0 1px;
    }
  }

	.merchery-header-search {
    margin-left: 14px;
    z-index: 2;
  }
}

@media(max-width: 1180px) {
  .merchery-header-inner {
    padding-right: 16px;
  }
}