.page-is-out-of-date {
  border-radius: 4px;
  background: linear-gradient(180deg, #517EF2 0%, #5079E0 100%);

  padding: 24px 27px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  position: absolute;
  right: 0;
  bottom: 0;
  color: white;

  max-width: 318px;

}

.page-is-out-of-date_confirm-btn {
  margin-top: 14px;
}

.page-is-out-of-date_close-btn {
  padding: 5px;
  margin: -5px;
  position: absolute;
  right: 11px;
  top: 11px;

  i {
    font-size: 20px;
    color: #FFF;

    opacity: 0.7;
  }
}