
.create-order {
  position: relative;
  row-gap: 20px;
  display: grid;
  flex-direction: column;
  width: calc(100% - 317px);

  .to-orders-btn + * {
    margin-top: -20px;
  }

  .create-order-component {
    width: 100%;
  }

  .op-footer {
    grid-template-columns: max-content auto;
  }

  .order-page-container {
    display: flex;
    flex-direction: column;

    h3 {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
    }
  }

  .inputs-container {
    display: grid;
    grid-template-columns: 247px auto;
    column-gap: 12px;
  }
}

.create-order-client {
  display: flex;
  flex-direction: column;
  row-gap: 13px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 20px 24px 36px 27px;
  position: relative;
}

.product-page__right-side-bar {
  padding-top: 24px;
  padding-bottom: 32px;  
  overflow: visible;
  grid-area: sidebar;
  align-self: start;
}

.product-page__right-side-bar__label {
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  &.product-page__collections, &.product__tags {
    position: relative;
  }
}

.merchery-bubbles {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  column-gap: 8px;
  row-gap: 4px;
  flex-wrap: wrap;
}

.bubble {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 4px;
  padding: 5px 12px 5px 12px;  
  color: $activeSecondColor;
  border: $activeSecondColor 1px solid;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  font-weight: 400;
  border-radius: 4px;
  line-height: 22px;
  position: relative;
  width: max-content;

  &.bubble--deletable {
    padding-right: 30px;
  }
}

.bubble-text {
  line-height: 17px;
}

.bubble-btn {
  position: absolute;
  right: 6px;
  padding: 5px;
  margin: -5px;
  font-size: 16px;
}

.product-page-element.product-page__right-side-bar {
  row-gap: 16px;
  position: unset;
}

@media(max-width: 1180px) {
  .create-order {
    width: 100%;
  }

  .product-page-element.product-page__right-side-bar {
    position: unset;
    width: 100%;
  }

  .product-page__right-side-bar {
    padding-top: 16px;
    padding-bottom: 18px;  
  }
}