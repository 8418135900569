.my-switcher {
  display:flex;
  flex-direction: row;
  height: 30px;
  align-items: center;
}

.my-switcher__item {
  padding: 6px 12px;
  border: 1px solid rgba($blackSecondColor, 0.25);
  border-radius: 0;

  &.selected {
    background: linear-gradient(180deg, #517EF2 0%, $activeSecondColor 100%);
    color: #FFFFFF;
  }

  &:first-of-type {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:last-of-type {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &:not(:last-of-type) {
    border-right: 0;
  }
}