.clients-page {
  display: flex;
  flex-direction: column;
  gap: 26px;
}

.clients-page__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.clients-page__filters-and-export {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 70px;
  margin-bottom: 8px;
}

.clients-search-bar {
  @extend .search-and-filters-bar;
  margin: 0;
}

.clients-table__wrapper {
  display: flex;
  flex-direction: column;
}

.clients-table__items__wrapper {
  margin-top: 15px;
}

.clients-table {
  gap: 9px;
}

.clients-table__cells-template {
  display: grid;
  grid-template-columns: 80px auto 95px 18% 49px 8% 8% 44px 40px;
  column-gap: 1%;

  &.table__row {
    .table__cell {
      padding: 17px 0;
    }
    
    //&:not(:first-of-type) {
    //  border-top-width: 0;
    //}

    &.active, &.active:hover {
      border-color: #517EF2;

      .checkbox {
        @include flex-center;
        border-color: $activeSecondColor;

        div {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: $activeSecondColor;
        }
      }
    }

    &.focused {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
}

.id-and-check-wrapper { 
  display: flex;
  position: relative;
  justify-content: center;
  box-sizing: content-box;
}

.select-outside {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 100%;
  top: 0;
  left: -48px;
  position: absolute;
    
  &:hover, &:focus {
    .checkbox {
      display: flex;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
    }
  }
  .checkbox {
    margin: auto;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid $grayColor;
    display: none;
    transition: ease box-shadow .2s;
  }
}

.clients-table__email-cell {
  cursor: copy;
}


.client-page {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.client-page__header {
  display: flex;
  position: relative;
  justify-content: space-between;
}

.client-page__header__left-inner, .client-page__header__right-inner {
  display: flex;
  column-gap: 23px;
  align-items: center;
  row-gap: 24px;
}

.client-summary {
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 142px max-content;

  &.client-summary__points--off {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.client-summary__grid-element {
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  padding: 24px;

  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.client-summary .client-page__main-labels { display: grid; }

.client-page__main-labels {
  grid-column: 1 / 3;
  
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  row-gap: 24px;
  column-gap: 32px;

  & > .grid-element:first-of-type {
    grid-column: 1 / 3;
  }
}

.client-summary__item {
  color: #4A5267;

  .main-item {
    font-weight: 600;
    font-size: 28px;
    line-height: 38px;
  }
}

.client-orders__wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.client-order {
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
  border-radius: 4px;

  .row-item {
    padding-right: 30px;
    padding-left: 24px;
    gap: 2%;
    display: grid;
    grid-template-columns: 56px 3fr 1fr 1fr 1fr 60px 13%;

    .right-align {
      text-align: right;
      justify-content: flex-end;
    }
  }
}

.client-order__header {
  display: flex;
  justify-content: space-between;
  height: 60px;
  align-items: center;
  padding: 0 30px 0 24px;  
  position: sticky;
  top: -1px;
  z-index: 2;
  will-change: transform;
  background-color: hsl(0, 0%, 100%);
  border-bottom: 1px solid #E4E4E4;
  cursor: pointer;
}

.client-order__header__left-side {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.client-order__header__right-side {
  display: flex;
  align-items: center;

  // .currency-pt-sans {
    // font-family: "Roboto";
  // }
  &.client-order--cancelled {
    .client-order__price {
      text-decoration: line-through;
    }
    .currency-pt-sans {
      text-decoration: none;
    }
  }

  &.client-order--full-refund {
    .client-order__price-with-currancy--order-total {
      .client-order__price {
        text-decoration: line-through;
      }
      .currency-pt-sans {
        text-decoration: none;
      }
    }
  }
}

.client-order__header__statuses-wrapper {
  display: flex;
  gap: 12px;
  margin-left: 12px;
}

.row-item__refund-status {
  text-transform: uppercase;
  color: #FFFFFF;
  border-radius: 4px;
  padding: 4px 12px 4px 10px;
  width: max-content;
}

.client-order__delivery {
  display: flex;
  justify-content: space-between;
  padding: 16px 32px 24px 24px;
  position: sticky;
  bottom: 1px;
  z-index: 1;
  will-change: transform;
  background-color: hsl(0, 0%, 100%);
  border-top: 1px solid #E4E4E4;

  &.display-none {
    display: none;
  }
}

.client-order__delivery__left-side, .client-order__delivery_right-side {
  display: flex;
  flex-direction: column;
  gap: 9px;
}

.row-item__image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.client-order-history {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.show-hide-btn  {
  padding: 5px;
  margin: -5px;
}

.show-hide-btn.closed img {
  transform-origin: center;
  transform: rotate(-90deg);
}

.config-btn {
  padding: 17px 5px;
  margin: -17px -5px;
}

.general-popup.client-create-popup {
  width: 750px;
  gap: 32px;
  padding: 24px 28px 32px 28px;
}

.general-popup.group {
  width: 200px;
}

.client-create-popup__btn-wrapper {
  display: flex;
  gap: 30px;
}

.clients-table-loader {
  width: 100%;
  height: 281px;
  overflow: hidden;
  position: relative;
  background: 
    linear-gradient(90deg,#0000 33%,rgba(255,255,255,0.3) 50%,#0000 66%) rgb(220, 220, 220);
  // background: 
  //   linear-gradient(90deg, rgb(143, 142, 141) 0%, rgb(237, 235, 233) 50%, rgb(143, 142, 141) 100%) 0px 0px / 100% 100% rgb(243, 242, 241);
  mask-image: url('../../../img/client-table-loader.svg') ;
  mask-size: cover;
  background-size:300% 100%;
  animation: shine 2s infinite;
}

.clients-to-delete h3 {
  padding-bottom: 4px;
}

.client-order__items {
  .refund-flag__icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.clients-delete-popup {
  row-gap: 12px;
}

@keyframes shine {
  0% {
    background-position: right;    
  }
}

@media(max-width: 1180px) {
  #orders-table th {
    font-size: 9px;
  }
  .clients-page {
    margin-left: 32px;
  }
}