.trash-btn {
  padding: 5px;
  margin: -5px;
  color: rgba(74, 82, 103, 0.5);
  grid-area: trash;

  i {
    font-size: 16px;
  }

  .trash-btn_icon_hover {
    display: none;
  }

  &:hover, &:focus {
    color: $redColor;
    
    .trash-btn_icon_hover {
      display: unset;
    }
    .trash-btn_icon_default {
      display: none;
    }
  }
}