.my-select-input {
  position: relative;
  flex: 1;

  .merchery-label {
    height: 100%;
  }

  .merchery-label__input {
    padding-right: 32px;
  
    &:focus::placeholder {
      color: $blackSecondColor;
    }
  }
}

.my-select-input--tall-input .merchery-label__input {
  height: 48px;
}

.my-select-option { 
  padding: 5px 12px;
  cursor: pointer;
  &.focused {
    background-color: $activeSecondColor;
    color: white;
  }
  &:hover:not(.focused) {
    background-color: $lightGrayColor;
  }
}

.my-select-input__down-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  font-size: 15px;
  transform: translateY(-50%);
  pointer-events: none;
}

.general-popup.my-select-options {
  width: 100%;
  font-size: 14px;

  &:not(.general-popup-upside-down) {
    top: calc(100% + 2px);
  }

  .popup-group {
    padding: 5px 0;
  }

  .popup-element {
    padding: 5px 12px;
    cursor: pointer;
  }
}