.multi-select {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  

  .checkbox-wrapper {
    justify-content: flex-start;
    padding: 4px;
    margin: -4px;
  }
}