.general-popup {
  width: 180px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  font-size: 13px;
  line-height: 18px;
  background-color: $whiteColor;
  color: $blackSecondColor;
  position: absolute;
  z-index: 20;
  top: 43px;
  left: 0;
  text-transform: none;
  opacity: 1;
  display: flex;
  flex-direction: column;
  
  &.create-order-clients-popup .popup-element {
    @include popup-btn;
    flex-direction: column;
    align-items: stretch;
  }

  .popup-group {
    width: 100%;
  }

  .popup-element {
    width: 100%;
    text-align: left;

    @include popup-btn;
    &:hover, &:focus {
      @include popup-btn-hover;
      
      .checkbox {
        border-color: #517EF2;
      }
    }

    span {
      word-break: normal;
      word-break: break-all;
    }
  }

  &.sub-popup {
    z-index: 21
  }
  
  &.status > .popup-group,
  &.admin-popup > .popup-group {

    &:not(:first-of-type) {
      border-top: 1px solid #E6E6E6;
    }

    .popup-element {    
      overflow-wrap: anywhere;
      width: 100%;
      justify-content: flex-start;

      span {
        position: relative;
        display: flex;
        align-items: center;
      }
    }
  }

  &.status {
    .popup-element span div {
      position: absolute;
      width: 12px;
      height: 12px;
      border-radius: 2px;
      left: 0;
    }
  }

  .close-btn {
    position: absolute;
    display: flex;
    box-sizing: content-box;
    padding: 5px;
    margin: -5px;
    right: 11px;
    top: 11px;

    &:hover, &:focus {
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
    }
  }

  &.manager {
    .popup-element {
      @include popup-btn;
      &:hover {
        @include popup-btn-hover;
      }
    
      .checkbox {
        border-color: #517EF2;
      }
      
      & > span {
        padding-left: 36px;
        text-align: left;
      }
    }
  }

  &.selected-row {
    top: calc(100% + 12px);
  }

  &.left-8px {
    left: -8px;
  }

  &.table-popup {
    width: max-content;
    left: 50%;
    transform: translateX(-50%);
    top: calc(100% + 10px);
  }
  
  &.order-page-select {
    width: max-content;
    min-width: 100%;
    left: 50%;
    transform: translateX(-50%);
  }

  &.general-popup-upside-down {
    transform: translateY(-100%);
    top: -10px;
  }

  &.fixed-on-center {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // padding: 25px 27px 29px;
    // row-gap: 15px;
    z-index: 10;
  }

  &.discount, &.promo {
    height: auto;
    width: 385px;
    padding: 27px 25px 30px 29px;
    row-gap: 15px;
  }

  &.general-popup-upside-down.table-popup {
    transform: translate(-50%, -100%);
    top: -10px;
  }

  &.select-category {
    right: 0;
    left: unset;
    width: 175px;
    top: 100%;

    & .select-item:hover,
    & .select-item:focus {
      background: $activeSecondColor;
      color: white;
    }
  }

  &.add-by-link-popup {
    width: 365px;
    padding: 10px;
    right: -15px;

    .popup-group {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
    }
  }

  &.admin-popup {
    right: -10px;
    left: unset;
    transform: translate(0, 5px);
    
    & > div > a {
      @include popup-btn;
    }
  }

  & .checkbox {
    @include flex-center;
    width: 16px;
    height: 16px;
    border: 1px solid #AFAFAF;
    border-radius: 2px;
    transition: all ease .3s;

    i {
      display: none;
    }
  }
  
  // &:not(.sidebar-popup) .active > div {
  //   border: 1px solid $activeSecondColor;

  //   i {
  //     color: $activeSecondColor;
  //     font-size: 16px;
  //     font-weight: 500;
  //     display: unset;
  //   }
  // }

  &.manager .popup-element:not(.general-popup-clean-btn) .selected,
  &.manager .popup-element:not(.general-popup-clean-btn) .not-selected-char {
    @include flex-center;
    position: absolute;
    width: 20px;
    height: 20px;
    color: $whiteColor;
    border-radius: 50%;
    font-size: 12px;
    line-height: 20px;
  }
  
  &.manager .popup-element.active {
    .selected {
      color: $activeSecondColor;
      display: unset;
      border: 1px solid $activeSecondColor;
      z-index: 10;
      background-color: $whiteColor;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &.delivery-info {
    width: max-content;
    max-width: 400px;
    left: unset;
    top: unset;
    right: 0;
    background: #FFFFFF;
    border-radius: 4px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    padding: 18px;
    border: 1px solid #c4c4c4a4;
    cursor: auto;
    min-width: unset;
    transform: translate(50%);
    bottom: 100%;
  }
}

.general-popup-clean-btn {
  color: $activeSecondColor;
  font-weight: 500;
}

.discount-promo-set-discount-promo {
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  .discount-promo-set-discount-promo-top {
    display: flex;
    align-items: center;

    &.promo {
      margin-bottom: 5px;
    }

    &.promo .merchery-label {
      margin-right: 0;
      border-radius: 4px 0 0 4px;
    }

    .merchery-label {
      flex-grow: 1;
    }
  }

  .discount-promo-set-discount-promo-bottom {
    display: flex;

    &.discount {
      padding-right: 15px;
      flex-wrap: wrap;
    }

    &.promo {
      justify-content: space-between;
    }

    .set-discount-btn {
      width: max-content;
      min-width: 40px;
      height: 22px;
      background: rgba(76, 93, 250, 0.07);
      border-radius: 4px;
      font-size: 12px;
      line-height: 16px;
      color: $activeSecondColor;
      opacity: 0.7;
      padding: 0 5px;
      margin-right: 8px;
      
      &:hover, &:focus, &.active {
        opacity: 1;
      }
    }
    
    .discount-clean {
      font-size: 12px;
      line-height: 22px;
      color: $activeSecondColor;
      cursor: pointer;
      padding: 3px;
      margin: -3px;
    }
  }
}

.discount-by {
  display: flex;
  box-sizing: border-box;
  
  &.blue-btn {
    padding: 0;
    width: 48px;
    height: 48px;
  }
  &.white-btn {
    padding: 0;
    width: 48px;
    height: 48px;
  }
  &.gray-btn {
    @include gray-btn;
    padding: 0;
    width: 48px;
    height: 48px;
    border: none;

    &:hover, &:focus {
      box-shadow: none;
    }
  }
  
  &:first-of-type {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  &:last-of-type {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.discount-promo-btns {
  display: flex;

  .blue-btn {
    flex-grow: 1;
  }
  .white-btn {
    flex-grow: 1;
    margin-right: 12px;
  }
}

.items-in-order-header {
  padding: 25px 30px 28px 26px;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .title-div {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
  }
  .text-div {
    font-size: 13px;
    line-height: 18px;
  }
  .search-item {
    margin: 15px 0 16px;
    width: 100%;
    display: flex;
    position: relative;
    align-items: stretch;

    .merchery-label {
      flex-grow: 1;
      border-radius: 4px 0 0 4px;
    }
  }
  .categories-btn {
    @include border-light-gray;
    min-width: 174px;
    border-radius: 0 4px 4px 0;
    background: linear-gradient(180deg, #FFFFFF 0%, #F7F7F7 100%);
    font-size: 13px;
    line-height: 18px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    padding-right: 11px;
    justify-content: space-between;
    color: $blackSecondColor;
    transition: ease box-shadow .2s;

    &:hover, &:focus {
      box-shadow: 0 2px 6px rgba(0,0,0, 0.05);
    }
  }
}

.confirm-popup {
  width: 538px;
  padding: 25px 28px 32px 27px;
  display: flex;
  gap: 24px;
}

.confirm-popup__body {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.confirm-popup__btn-wrapper {
  display: flex;
  justify-content: space-between;
}

.categories-filters__popup {
  right: -10px;
  left: unset;
  transform: translate(0, 5px);
  width: max-content;
  min-width: 220px;
}