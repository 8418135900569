.possible-values-popup {
  right: 0;
  left: 0;
  width: 100%;
  top: calc(100% + 5px);
}

.possible-values-wrapper {
  width: 100%;
  border: 1px solid rgba($lightGrayColor, .5);
  display: flex;
  flex-direction: column;
  text-align: left;
}

.possible-value {
  text-align: left;
  padding: 2px 12px;
  display: flex;
  font-size: 13px;
  height: 40px;
  align-items: center;
}